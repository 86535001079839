import { get, isEmpty, startCase } from 'lodash-es';
import { createSelector, defaultMemoize } from 'reselect';
import { parseShareLink } from '../../../components/formik/ShareLinks/ShareLinks';

export const AccountRooms = defaultMemoize(state => state.Data.accountRooms);

export const UserRooms = defaultMemoize((state, roomId) => state.Data.userRooms || []);

export const getRoomFeaturesAsOptions = defaultMemoize((state, key, features) => {
  return get(state, `Data.roomFeatures[${key}]`, [])
    .map(feature => {
      let featureOption = {
        ...feature,
        label: startCase(feature.feature),
        value: feature.feature_id,
      };
      const isExistingFeature =
        (features || []).findIndex(f => f.feature_id === feature.feature_id) !== -1;
      if (isExistingFeature) {
        featureOption.isExisting = true;
        featureOption.isDisabled = true;
      }
      return featureOption;
    })
    .sort((a, b) => {
      if (a.isExisting && !b.isExisting) {
        return 1;
      } else if (b.isExisting && !a.isExisting) {
        return -1;
      }
      return 0;
    });
});

export const getRoomResourceOptions = defaultMemoize((state, key, features) =>
  get(state, `Data.roomFeatures[${key}]`, []).reduce((dict, e) => {
    if (dict.findIndex(resource => resource.label === e.resource) === -1) {
      dict.push({ label: e.resource, value: e.resource_id });
    }
    return dict;
  }, []),
);

export const getRoom = defaultMemoize((state, accessCode) =>
  get(state, `Data.room[${accessCode}]`, {}),
);

export const getError = defaultMemoize((state, accessCode) =>
  get(state, `Data.room.error`, {}),
);

export const IsFetching = defaultMemoize((state, accessCode) =>
  get(state, `Data.isFetching`, false),
);

export const getRoomCodes = defaultMemoize(
  (state, roomId) => state.Data.roomCodes[roomId] || [],
);

export const getRoomDocuments = defaultMemoize((state, roomId) =>
  (state.Data.roomDocuments[roomId] || []).sort((a, b) => a.order - b.order),
);

export const getRoomLinks = defaultMemoize(
  (state, roomId) => state.Data.roomLinks[roomId] || [],
);

export const getRoomParticipants = defaultMemoize(
  (state, roomId) => state.Data.roomParticipants[roomId] || [],
);

export const getRoomAccessLog = defaultMemoize(
  (state, roomId) => state.Data.roomAccessLog[roomId] || [],
);

export const getRoomHistory = defaultMemoize(
  (state, roomId) => state.Data.roomHistory[roomId] || [],
);

export const getRoomSharingMeta = createSelector(
  getRoomCodes,
  getRoomAccessLog,
  getRoomHistory,
  getRoomParticipants,
  (codes, accessLog, roomHistory, participants) => {
    let standingFormLink = null;
    let standingFormLinkLabel = null;

    let standingForms = [];
    let standingFormsCompleted = 0;

    let otherForms = [];
    let otherFormsCompleted = 0;
    let totalTimesVisited = 0;
    let totalDocInteractions = 0;
    let totalPrivateLinks = 0;
    let anonymous = {
      name: 'Anonymous',
      lastVisited: null,
      timesVisited: 0,
      docInteractions: 0,
      forms: [],
      codes: [],
      history: [],
    };
    let usersByEmail = {};

    participants.forEach(val => {
      const existingUser = usersByEmail[val.email];
      if (existingUser) {
        usersByEmail[val.email] = {
          ...existingUser,
          codes: [...existingUser.codes, val.room_access],
          name: existingUser.name.trim() || val.name.trim(),
          user_id: existingUser.user_id || val.user_id,
        };
      } else if (val.email) {
        usersByEmail[val.email] = {
          created_at: val.room_access?.created_at,
          email: val.email,
          name: val.name.trim(),
          user_id: val.id,
          room_id: val.room_access.room_id,
          timesVisited: 0,
          docInteractions: 0,
          lastVisited: null,
          codes: [val.room_access],
          forms: [],
          history: [],
        };
      }
    });

    codes.forEach(code => {
      if (!code.revoked_at) {
        if (code.outside_form?.metadata?.type === 'persistent') {
          standingFormLink = code;
          standingFormLinkLabel = standingFormLink.outside_form?.name;
        } else if (code.outside_form?.metadata?.type === 'persistent_instance') {
          standingForms.push(code);
          if (code.outside_form.state !== 'new') {
            standingFormsCompleted++;
          }
        } else if (!isEmpty(code.outside_form) && !code.outside_form.assigned_email) {
          otherForms.push(code);
          if (code.outside_form.state !== 'new') {
            otherFormsCompleted++;
          }
        } else {
          if (code.identifier || code.user?.email) {
            let existingUser = usersByEmail[code.identifier || code.user?.email];
            if (!existingUser) {
              existingUser = Object.values(usersByEmail).find(
                v => !!v.user_id && v.user_id === code.user_id,
              );
            }
            if (existingUser) {
              if (existingUser.codes.length > 0) {
                totalPrivateLinks++;
              }
              existingUser.user_id = existingUser.user_id || code.user_id;
              existingUser.name =
                existingUser.name.trim() || (code.user?.name || '').trim();
              const codeIndex = existingUser.codes.findIndex(
                v => !!v.code && v.code === code.code,
              );
              if (codeIndex === -1) {
                existingUser.codes.push(code);
              }
              if (code?.outside_form?.assigned_email) {
                existingUser.forms.push(code.outside_form);
              }
              if (
                !existingUser.created_at ||
                new Date(existingUser.created_at).valueOf() >
                  new Date(code.created_at).valueOf()
              ) {
                existingUser.created_at = code.created_at;
              }
              usersByEmail[existingUser.email] = existingUser;
            } else if (code.identifier || code.user?.email) {
              totalPrivateLinks++;
              usersByEmail[code.identifier] = {
                created_at: code.created_at,
                email: code.identifier || code.user?.email,
                name: (code.user?.name || '').trim(),
                room_id: code.room_id,
                user_id: code.user_id,
                timesVisited: 0,
                docInteractions: 0,
                lastVisited: null,
                codes: [code],
                forms: code?.outside_form?.assigned_email ? [code.outside_form] : [],
                history: [],
              };
            }
          } else {
            anonymous.codes.push(code);
          }
        }
      }
    });

    accessLog.forEach(log => {
      totalTimesVisited++;
      if (log.user?.email) {
        let existingUser = usersByEmail[log.user.email];
        if (!existingUser) {
          existingUser = Object.values(usersByEmail).find(v => v.user_id === log.user_id);
        }
        if (existingUser) {
          existingUser.history.push(log);
          existingUser.timesVisited++;
          existingUser.email = existingUser.email || log.user?.email;
          existingUser.name = existingUser.name || (log.user?.name || '').trim();
          if (
            !existingUser.created_at ||
            new Date(existingUser.created_at).valueOf() >
              new Date(log.created_at).valueOf()
          ) {
            existingUser.created_at = log.created_at;
          }
          if (
            new Date(existingUser.lastVisited).valueOf() <
            new Date(log.created_at).valueOf()
          ) {
            existingUser.lastVisited = log.created_at;
          }
        } else {
          existingUser = {
            created_at: log.created_at,
            email: log.user.email,
            name: (log.user.name || '').trim(),
            room_id: log.room_id,
            user_id: log.user_id,
            timesVisited: 1,
            docInteractions: 0,
            lastVisited: log.created_at,
            forms: [],
            codes: [],
            history: [log],
          };
        }
        usersByEmail[existingUser.email] = existingUser;
      } else {
        if (
          new Date(log.created_at).valueOf() - new Date(anonymous.lastVisited).valueOf()
        ) {
          anonymous.lastVisited = log.created_at;
        }
        anonymous.timesVisited++;
        anonymous.history.push(log);
      }
    });

    roomHistory.forEach(val => {
      if (!val.archived) {
        const historyAction = val.history?.action || '';
        if (historyAction.includes('visit')) {
          totalTimesVisited++;
        } else if (
          historyAction.includes('document') &&
          (historyAction !== 'add_document' || historyAction !== 'remove_document')
        ) {
          totalDocInteractions++;
        }
        if (val.user?.email) {
          let existingUser = usersByEmail[val.user.email];
          if (!existingUser) {
            existingUser = Object.values(usersByEmail).find(
              v => v.user_id === val.user_id,
            );
          }
          if (existingUser) {
            existingUser.history.push(val);
            if (historyAction.includes('visit')) {
              existingUser.timesVisited++;
            } else {
              existingUser.docInteractions++;
            }
            existingUser.email = existingUser.email || val.user.email;
            existingUser.name = existingUser.name || (val.user?.name || '').trim();
            if (
              !existingUser.created_at ||
              new Date(existingUser.created_at).valueOf() >
                new Date(val.created_at).valueOf()
            ) {
              existingUser.created_at = val.created_at;
            }
            if (
              new Date(existingUser.lastVisited).valueOf() <
              new Date(val.created_at).valueOf()
            ) {
              existingUser.lastVisited = val.created_at;
            }
          } else {
            totalTimesVisited++;
            existingUser = {
              email: val.user.email,
              name: (val.user.name || '').trim(),
              room_id: val.room_id,
              user_id: val.user_id,
              timesVisited: 1,
              docInteractions: historyAction.includes('visit') ? 0 : 1,
              lastVisited: val.created_at,
              forms: [],
              codes: [],
              history: [val],
            };
          }
          usersByEmail[existingUser.email] = existingUser;
        } else {
          if (
            new Date(val.created_at).valueOf() - new Date(anonymous.lastVisited).valueOf()
          ) {
            anonymous.lastVisited = val.created_at;
          }
          if (historyAction.includes('visit')) {
            anonymous.timesVisited++;
          } else {
            anonymous.docInteractions++;
          }
          anonymous.history.push(val);
        }
      }
    });

    let usersArr = Object.values(usersByEmail)
      .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
      .map(user => ({
        ...user,
        history: user.history.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at),
        ),
      }));

    return {
      standingFormLink,
      standingFormLinkLabel,
      standingFormsCompleted,
      standingForms,
      otherForms,
      otherFormsCompleted,
      totalDocInteractions,
      totalPrivateLinks,
      totalTimesVisited,
      usersByEmail: [...usersArr, anonymous],
    };
  },
);

const addFeatureToDict = (e, passedDict) => {
  if (!passedDict[e.resource_custom_label]) {
    passedDict[e.resource_custom_label] = {
      resource_custom_label: e.resource_custom_label,
      resource_type: e.resource_type,
      resource_type_id: e.resource_type_id,
      resource_variable_name: e.resource_variable_name,
      resources: {
        [e.resource]: {
          resource: e.resource,
          resource_id: e.resource_id,
          resource_custom_label: e.resource_custom_label,
          resource_type: e.resource_type,
          resource_type_id: e.resource_type_id,
          resource_variable_name: e.resource_variable_name,
          features: [e],
        },
      },
    };
  } else if (!passedDict[e.resource_custom_label].resources[e.resource]) {
    passedDict[e.resource_custom_label].resources[e.resource] = {
      resource: e.resource,
      resource_id: e.resource_id,
      resource_custom_label: e.resource_custom_label,
      resource_type: e.resource_type,
      resource_type_id: e.resource_type_id,
      resource_variable_name: e.resource_variable_name,
      features: [e],
    };
  } else {
    const featuresArr = get(
      passedDict,
      `["${e.resource_custom_label}"].resources["${e.resource}"].features`,
      [],
    );
    passedDict[e.resource_custom_label].resources[e.resource].features = [
      ...featuresArr,
      e,
    ];
  }
  return passedDict;
};

export const getRoomWithUpdatedFeatures = createSelector(getRoom, room => {
  if (!room || !room.room) {
    return room;
  }
  let entityMeta = {};
  let docFeatures = [];
  let infoCardFeatures = {};
  // let isChecklistsAvailable = false;
  const updatedFeatures = (room.room?.features || []).reduce((dict, e) => {
    if (e.feature_type.feature_type === 'boolean') {
      const valStr = e.value + '';
      e.value = valStr === 'true' || valStr === '1' ? 'Yes' : 'No';
    } else if (e.feature_type.feature_type === 'document') {
      if (!!e.value) {
        e.fileId = e.value;
        e.value = 'Uploaded';
        e.isDocument = true;
        const docIndex = (room.room?.documents || []).findIndex(
          doc => doc.box_file_id === e.fileId,
        );
        if (docIndex !== -1) {
          e.isFieldHiding = true;
          docFeatures.push({
            ...e,
            docMeta: {
              ...(room.room?.documents || [])[docIndex],
              room_feature_id: e.room_feature_id,
            },
          });
        }
      }
    } else if (e.feature_type.feature_type === 'share_links' && !!e.value) {
      e.value = parseShareLink(e.value);
    } else if (
      e.feature_type.feature_type === 'social_security' &&
      e.value &&
      e.value.length === 9
    ) {
      e.value = '*****' + e.value.slice(5);
    } else if (e.feature === 'filing_state') {
      e.feature_type.question_label = 'Filing State';
    }
    if (e.resource_type_id === 2 && e.resource_label !== 'Fund Performance') {
      if (!entityMeta.features) {
        entityMeta = {
          resource: e.resource,
          resource_id: e.resource_id,
          resource_custom_label: e.resource_custom_label,
          resource_type: e.resource_type,
          resource_type_id: e.resource_type_id,
          resource_variable_name: e.resource_variable_name,
          features: [e],
        };
      } else {
        entityMeta.features.push(e);
      }
    }
    dict = addFeatureToDict(e, dict);
    // if (e.resource_type_id === 2 && e.resource_label !== 'Fund Performance') {
    //   infoCardFeatures = addFeatureToDict(e, infoCardFeatures);
    // }
    // if (e.resource_type_id === 25) {
    //   infoCardFeatures = addFeatureToDict(e, infoCardFeatures);
    //   isChecklistsAvailable = true;
    // }
    return dict;
  }, {});

  return {
    ...room,
    room: {
      ...room.room,
      features: Object.entries(updatedFeatures).sort((a, b) => {
        if (a[0] === 'Company') {
          return -1;
        }
        if (b[0] === 'Company') {
          return 1;
        }
        if (b[0] === 'Directors') {
          return -1;
        }
        return 0;
      }),
      entityMeta,
      infoCardFeatures: Object.entries(infoCardFeatures),
      docFeatures,
      // isChecklistsAvailable,
    },
  };
});
