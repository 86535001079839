import React, { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Document, Page } from 'react-pdf';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { Form, Formik } from 'formik';
import { ReactComponent as DocPreviewSVG } from '../../assets/images/doc_preview.svg';

import { Button, Dropdown, MyModal, Tooltip } from '../common';
import { updateTaskVariant } from '../../redux/modules/Project/operations';
import InputStyledCheckbox from '../common/InputStyledCheckbox';
import { requestHeaders } from '../../redux/utils/api';
import { updateTransaction } from '../../redux/modules/Transaction/operations';

import './OverviewCard.scss';
import { getBoxFileInfo } from '../../redux/modules/Cabinet/operations';
import handlePath from '../../utils/handlePath';
import { get, startCase } from 'lodash-es';
import { BoxToken } from '../../redux/modules/Cabinet/selectors';
import ContentPreview from 'box-ui-elements/es/elements/content-preview';
import { setNotice } from '../../redux/modules/UI/actions';
import { BrandMeta } from '../../redux/modules/UI/selectors';

const bem = elementName => `OverviewCard${elementName ? '__' + elementName : ''}`;

const OverviewCard = ({
  handleTransactionAction,
  isInitCollapsed,
  isPdfHiding,
  params: { companyId, moduleId, projectId },
  transaction,
}) => {
  const {
    allow_reopen,
    disabled,
    id: transactionId,
    is_started,
    label,
    open,
    state,
    tasks,
    template: { delete_level, is_assessment } = {},
    validated_questionnaire,
  } = transaction;
  const history = useHistory();

  const [isActionsShowing, setIsActionsShowing] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(isInitCollapsed);
  const [isFocused, setIsFocused] = useState(false);

  const isReopenAllowed =
    !open &&
    (tasks.findIndex(e => e.mode !== 'Upload' && e.state === 'completed') === -1 ||
      allow_reopen);

  let isDeletable = false;
  if (delete_level === 'completed') {
    isDeletable = true;
  } else if (delete_level === 'unsubmitted' && !!open) {
    isDeletable = true;
  } else if (
    delete_level === 'submitted' &&
    tasks.findIndex(e => e.state === 'completed') === -1
  ) {
    isDeletable = true;
  }

  let statusLabel = 'Pending';
  let statusLevel = 'med';
  if (!!open) {
    if (!is_started) {
      statusLabel = 'Not Started';
      statusLevel = 'med';
    } else if (!validated_questionnaire) {
      statusLabel = 'Form Incomplete';
      statusLevel = 'med';
    } else {
      statusLabel = 'In Progress';
      statusLevel = 'med';
    }
  } else if (tasks.findIndex(e => e.state !== 'completed') === -1) {
    statusLabel = 'Completed';
    statusLevel = '';
  } else if (tasks.findIndex(e => get(e, 'document.revision_count', 1) > 1) !== -1) {
    statusLabel = 'Awaiting Approval';
    statusLevel = 'high';
  } else if (tasks.findIndex(e => e.status_label === 'Awaiting Signature') !== -1) {
    statusLabel = 'Awaiting Signature';
    statusLevel = 'high';
  } else if (state) {
    statusLabel = state;
    statusLevel = 'high';
  }

  return (
    <div className={bem('wrapper')}>
      <div className={`${bem()}${disabled ? ' disabled' : ''}`}>
        <div
          className={classnames(bem('top'), {
            collapsed: isCollapsed,
            assessment: is_assessment,
          })}
          onClick={() =>
            !is_assessment &&
            history.push(
              handlePath(
                `/workbench/${moduleId}/${projectId}/${transactionId}/task-view`,
                companyId,
              ),
            )
          }
        >
          <div className={bem('topLabel')}>
            <Button
              buttonType="icon"
              size="sm"
              className={classnames(bem('toggleCollapse'), {
                isCollapsed,
                isFocused,
              })}
              onMouseLeave={e => {
                setIsFocused(false);
              }}
              onClick={e => {
                e.stopPropagation();
                setIsCollapsed(!isCollapsed);
                setIsFocused(true);
              }}
            >
              <FontAwesomeIcon icon="chevron-down" />
            </Button>
            <h2 className={`${bem('title')}${label.length > 33 ? ' long' : ''}`}>
              {label}
            </h2>
          </div>
          <div className={`${bem('topStatus')} ${statusLevel}`}>{statusLabel}</div>
          <div className={bem('topActions')}>
            <Button
              buttonType="icon"
              size="sm"
              onClick={e => {
                e.stopPropagation();
                setIsActionsShowing(!isActionsShowing);
              }}
            >
              <FontAwesomeIcon icon="ellipsis-v" />
            </Button>
            {isActionsShowing && (
              <Dropdown
                onClose={e => {
                  e.stopPropagation();
                  setIsActionsShowing(false);
                }}
              >
                <Button
                  buttonType="secondary"
                  isWarning
                  isDisabled={!isReopenAllowed}
                  onClick={e => {
                    e.stopPropagation();
                    handleTransactionAction('reopen', transactionId, label);
                    setIsActionsShowing(false);
                  }}
                >
                  Reopen Task
                </Button>
                {isDeletable && (
                  <Button
                    buttonType="secondary"
                    isWarning
                    onClick={e => {
                      e.stopPropagation();
                      handleTransactionAction('delete', transactionId, label);
                      setIsActionsShowing(false);
                    }}
                  >
                    Delete Task
                  </Button>
                )}
                {!open && (
                  <Button
                    buttonType="secondary"
                    onClick={e => {
                      e.stopPropagation();
                      handleTransactionAction('review_inputs', transactionId, label);
                      setIsActionsShowing(false);
                    }}
                  >
                    Review Inputs
                  </Button>
                )}
                {!!open && !!validated_questionnaire && (
                  <Button
                    buttonType="secondary"
                    to={handlePath(
                      {
                        pathname: `/workbench/${moduleId}/${projectId}/${transactionId}/task-view`,
                        state: { isReopening: !!validated_questionnaire },
                      },
                      companyId,
                    )}
                  >
                    Edit Form
                  </Button>
                )}
              </Dropdown>
            )}
          </div>
        </div>
        {!!disabled && (
          <div className={bem('subtop')}>
            <FontAwesomeIcon className={bem('error')} icon={['fal', 'info-circle']} />
            <p>Prior Task submissions required before submitting this task.</p>
          </div>
        )}
        {!isCollapsed && (
          <>
            <div className={bem('divider')} />
            <div className={bem('list')}>
              {tasks.map((task, ind) => (
                <TaskRow
                  key={`transaction-${task.label}`}
                  isPdfHiding={isPdfHiding}
                  open={open}
                  task={task}
                  transactionId={transactionId}
                  projectId={projectId}
                  validatedQuestionnaire={validated_questionnaire}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const TaskRow = ({
  isPdfHiding,
  open,
  projectId,
  task,
  transactionId,
  validatedQuestionnaire,
}) => {
  let {
    id,
    document = {},
    label,
    state,
    status_label,
    mode,
    template: { description, deliverables, label: templateLabel, type, type_label },
    template_type,
    template_variant = {},
  } = task;
  const dispatch = useDispatch();

  const { customer_label: templateTypeLabel, variants = [] } = template_type || {};
  const { customer_label, id: variantId, preview = {} } = template_variant || {};

  const [isVariantsOpen, setIsVariantsOpen] = useState(false);

  const deliverable = get(deliverables, '[0]', {});
  const processLabel = document.filename || templateTypeLabel || label || templateLabel;
  const timeline = get(deliverable, 'timeline[0]', {});
  let processDescription = description || deliverable.label;
  let statusLabel = '';
  let statusLevel = 'low';
  let timelineLabel = '';
  let typeLabel = type_label || type || 'Status';
  let pdfFile = {
    customLabel: 'template',
    isTemplate: true,
    ...preview,
  };
  if (document.box_file_id) {
    pdfFile = {
      customLabel: 'document',
      isTemplate: false,
      ...document,
    };
  }
  if (isPdfHiding) {
    pdfFile.customLabel = 'preview ' + pdfFile.customLabel;
  }

  if (!type_label && ['System', 'Outside'].includes(type)) {
    typeLabel = '';
  }
  if (timeline.low_days) {
    timelineLabel = `${timeline.low_days} - ${timeline.high_days} days`;
  }

  /** handle status */
  if (open) {
    statusLabel = '';
  } else if (document.revision_count && document.revision_count > 0) {
    statusLabel = 'Awaiting Approval';
    statusLevel = 'high';
  } else if (status_label === 'Awaiting Signature') {
    statusLabel = 'Awaiting Signature';
    statusLevel = 'high';
  } else if (state === 'completed') {
    statusLabel = 'Completed';
    statusLevel = 'low';
  } else if (status_label !== 'Incomplete' && state !== 'incomplete') {
    statusLabel = startCase(status_label || state);
    statusLevel = 'med';
  }

  return (
    <div className={bem('listItemRow')}>
      <div className={classnames(bem('listItem first'), { isVariantsOpen })}>
        <h4 className={bem('listItemSublabel')}>
          {typeLabel + '  '}
          {type === 'Document' && mode === 'Upload' && ' (Upload)  '}
          {type !== 'Document' && !!timelineLabel && ` (${timelineLabel})  `}
          {statusLabel && <span className={statusLevel}>{statusLabel}</span>}
        </h4>
        <h3 className={bem('listItemLabel')}>
          {processLabel}
          {variants.length > 1 && !isVariantsOpen && !!open && mode !== 'Upload' && (
            <>
              {`--${customer_label} (`}
              <span onClick={() => setIsVariantsOpen(!isVariantsOpen)}>
                {isVariantsOpen ? 'Keep Template' : 'Change Template'}
                <FontAwesomeIcon icon={isVariantsOpen ? 'chevron-up' : 'chevron-down'} />
              </span>
              )
            </>
          )}
        </h3>
        {!!processDescription &&
          !!preview.box_file_id &&
          type === 'Document' &&
          mode !== 'Upload' && (
            <>
              <h4 className={bem('listItemSublabel')}>description</h4>
              <h3 className={bem('listItemLabel')}>{processDescription}</h3>
            </>
          )}
        {isVariantsOpen && (
          <Formik
            initialValues={{ template_variant_id: variantId }}
            onSubmit={({ template_variant_id }) => {
              dispatch(updateTaskVariant(id, { template_variant_id }, projectId)).then(
                e => {
                  if (validatedQuestionnaire && open) {
                    dispatch(
                      updateTransaction(transactionId, { validated_questionnaire: 0 }),
                    );
                  }
                  setIsVariantsOpen(false);
                },
              );
            }}
          >
            {formikProps => (
              <Form>
                <div className={bem('listItemTemplatesHeader')}>
                  <h3>Choose a Template:</h3>
                  <div className={bem('listItemTemplatesActions')}>
                    <Button
                      buttonType="secondary"
                      onClick={() => setIsVariantsOpen(false)}
                      size="sm"
                    >
                      Cancel
                    </Button>
                    <Button buttonType="primary" type="submit" size="sm">
                      Use Selected Template
                    </Button>
                  </div>
                </div>
                <div className={bem('listItemTemplates')}>
                  {variants.map((variant, index) => (
                    <div
                      className={bem('listItemTemplate')}
                      key={`variant-${index}-${variant.id}`}
                    >
                      <InputStyledCheckbox
                        checked={variant.id === formikProps.values.template_variant_id}
                        label={variant.customer_label}
                        name={`${variant.id}-${index}`}
                        onChange={() => {
                          formikProps.setFieldValue('template_variant_id', variant.id);
                        }}
                        type="radio"
                        value={variant.id}
                      />
                      {variant.description && (
                        <h4 className={bem('listItemTemplateHeader')}>
                          {'Description: '}
                          <p>{variant.description}</p>
                        </h4>
                      )}
                      {variant.partners.length > 0 && (
                        <h4 className={bem('listItemTemplateHeader')}>
                          Partners:{' '}
                          <p>
                            {variant.partners
                              .reduce((dict, e) => [...dict, e.partner.name], [])
                              .join(', ')}
                          </p>
                        </h4>
                      )}
                      {!!variant?.preview && (
                        <PDFViewer
                          isLinkOnHover
                          file={{
                            isTemplate: true,
                            ...variant.preview,
                          }}
                          width={200}
                          height={258}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
      {!isVariantsOpen && (
        <div className={bem('listItem')}>
          {type === 'Document' && mode !== 'Upload' && pdfFile.box_file_id && (
            <PDFViewer
              file={pdfFile}
              isPdfHiding={isPdfHiding}
              isLinkOnHover
              isDownloadable
              height={100}
              width={160}
            />
          )}
          {!!processDescription && (!pdfFile.box_file_id || mode === 'Upload') && (
            <>
              <h4 className={bem('listItemSublabel')}>description</h4>
              <h3 className={bem('listItemLabel')}>{processDescription}</h3>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export const PDFViewer = ({
  box_token,
  downloadableUrl,
  isDownloadable,
  isLinkOnHover,
  isPdfHiding,
  isShowOnHover,
  linkUrl,
  accessCode,
  file,
  readOnly,
  url,
  width = 220,
  height = 284,
  reports_feature_type_id,
  resource_id,
}) => {
  const dispatch = useDispatch();
  const { companyId } = useParams();
  const boxToken = useSelector(BoxToken);
  const brandMeta = useSelector(BrandMeta);
  const { box_file_id, customLabel, filename = '', isTemplate, label, preview } = file;
  const [numPages, setNumPages] = useState('loading');
  const [pageNumber, setPageNumber] = useState(1);
  const [isError, setIsError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const isPdf = (preview || filename || label || '').includes('.pdf');
  const fileToken = box_token || boxToken;
  const isFileAvailable =
    !!url || (!!box_file_id && (isPdf || !!fileToken || !!resource_id));
  const pdfLabel =
    customLabel || filename || label || `File${isFileAvailable ? ' Preview' : ' Saved'}`;

  const filePath = useMemo(() => {
    const queryUrl = resource_id
      ? `?reports_feature_type_id=${reports_feature_type_id}&resource_id=${resource_id}`
      : '';
    const dynamicUrl = accessCode
      ? `guest/rooms/${accessCode}/file/${box_file_id}${queryUrl}`
      : `account/box/file/${box_file_id}`;
    const updatedUrl = url || `${brandMeta.web_base_url}/api/${dynamicUrl}`;
    return {
      url: updatedUrl,
      httpHeaders: requestHeaders(true),
    };
  }, [
    accessCode,
    brandMeta.web_base_url,
    box_file_id,
    resource_id,
    reports_feature_type_id,
    url,
  ]);

  const onDocumentLoadSuccess = ({ numPages, ...rest }) => {
    setNumPages(numPages);
    setIsError(false);
  };

  const handleModalClose = e => {
    e.preventDefault();
    setIsOpen(false);
    setIsError(false);
    setPageNumber(1);
  };

  const handleResponseInterceptor = e => {
    if (e.type === 'error' || e instanceof Error) {
      this.setState({
        error: e.message || 'Unable to preview file at this time.',
      });
    }
    return e;
  };

  const isActionShowing = isLinkOnHover && !isError && isFileAvailable;
  const isBoxPreviewShowing = !!box_file_id && !!fileToken && !isTemplate && !url;
  const isDocumentShowing = !!url || (isPdf && isTemplate && filePath);
  const isErrorShowing = !box_file_id && !fileToken && isError && !url;
  const PDFViewerHeight =
    ((!!url || (!!box_file_id && !!fileToken)) && 800) ||
    (!isError && 400) ||
    'fit-content';

  return (
    <>
      <div
        className={classnames('PDFViewer__wrapper', {
          isError: isError || !numPages,
          isShowOnHover,
          isLinkOnHover: isLinkOnHover && fileToken,
          isPdfHiding: isPdfHiding || !fileToken,
        })}
      >
        {!isPdfHiding && isFileAvailable && (
          <div
            className="PDFViewer"
            onClick={e => {
              if (isLinkOnHover) {
                e.preventDefault();
                setIsOpen(!isOpen);
              }
            }}
            style={{
              width: width + 16,
              height: height + 16,
            }}
          >
            {isBoxPreviewShowing && (
              <ContentPreview
                fileId={box_file_id}
                token={fileToken}
                canDownload={!readOnly}
                responseInterceptor={handleResponseInterceptor}
              />
            )}
            {isDocumentShowing && (
              <Document
                file={filePath}
                loading={
                  <div className="PDFViewer__loading" style={{ height, width }}>
                    <FontAwesomeIcon icon="file" />
                    <h3>Loading PDF...</h3>
                  </div>
                }
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={() => setIsError(true)}
                onSourceError={() => setIsError(true)}
                error={
                  <div
                    className="PDFViewer__error"
                    style={{ height: height - 12, width: width - 12 }}
                  >
                    <DocPreviewSVG />
                  </div>
                }
              >
                <Page
                  loading={
                    <div
                      className="PDFViewer__loading"
                      style={{ height: height + 16, width: width + 16 }}
                    >
                      <FontAwesomeIcon icon="file" />
                      <h3>Loading Page....</h3>
                    </div>
                  }
                  onLoadError={() => setIsError(true)}
                  onRenderError={() => setIsError(true)}
                  pageNumber={pageNumber}
                  width={width}
                />
                {!isShowOnHover && <div style={{ width }} className="PDFViewer__fade" />}
              </Document>
            )}
          </div>
        )}
        {isActionShowing ? (
          <Button
            buttonType="link"
            className="PDFViewer__action"
            onClick={e => {
              e.preventDefault();
              setIsOpen(!isOpen);
            }}
          >
            {pdfLabel}
          </Button>
        ) : (
          <h4 className="PDFViewer__label">{pdfLabel}</h4>
        )}
      </div>
      {isLinkOnHover && (
        <MyModal
          className="PDFViewer__modal"
          isOpen={isOpen}
          onRequestClose={handleModalClose}
          overlayClassName="PDFViewer__modalOverlay"
        >
          <div
            className={`PDFViewer${isError || !numPages ? ' isError' : ''}`}
            style={{
              width: 600,
              height: PDFViewerHeight,
            }}
            onClick={e => e.preventDefault()}
          >
            <FontAwesomeIcon
              className="PDFViewer__modalExit"
              onClick={handleModalClose}
              icon="times"
            />
            <h2 className="PDFViewer__modalHeader">({pdfLabel})</h2>
            {isBoxPreviewShowing && (
              <ContentPreview
                fileId={box_file_id}
                responseInterceptor={handleResponseInterceptor}
                token={fileToken}
                hasHeader
              />
            )}
            {isErrorShowing && (
              <h3 className="PDFViewer__modalError">
                <FontAwesomeIcon icon="exclamation-circle" />
                {isError.message}
              </h3>
            )}
            {isDocumentShowing && (
              <Document
                file={filePath}
                error={
                  <div className="PDFViewer__error">
                    <DocPreviewSVG />
                    <h4>
                      Unable to preview file at this time. To view/download, ask the
                      account admin or get access through the connected account's File
                      Cabinet and Documents View.
                    </h4>
                  </div>
                }
                loading={
                  <div className="PDFViewer__loading large">
                    <FontAwesomeIcon icon="file" />
                    <h3>Loading PDF....</h3>
                  </div>
                }
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={() => setIsError(true)}
                onSourceError={() => setIsError(true)}
              >
                <Page
                  loading={
                    <div className="PDFViewer__loading large">
                      <FontAwesomeIcon icon="file" />
                      <h3>Loading Page....</h3>
                    </div>
                  }
                  onLoadError={() => setIsError(true)}
                  onRenderError={() => setIsError(true)}
                  pageNumber={pageNumber}
                  height={780}
                />
                <div className="PDFViewer__pageControls PDFViewer__pageControls--visible">
                  {numPages > 1 && (
                    <button
                      disabled={pageNumber <= 1}
                      type="button"
                      onClick={e => {
                        setPageNumber(pageNumber - 1);
                      }}
                      aria-label="Previous page"
                    >
                      <FontAwesomeIcon icon="chevron-left" />
                    </button>
                  )}
                  <span className="PDFViewer__pageNumbers">
                    {pageNumber} of {numPages}
                  </span>
                  {isDownloadable && (
                    <button
                      onClick={e => {
                        const newUrl = downloadableUrl || filePath.url;
                        var client = new XMLHttpRequest();
                        client.open('GET', newUrl);
                        client.withCredentials = true;
                        client.responseType = 'blob';
                        Object.entries(requestHeaders(!downloadableUrl)).forEach(
                          ([key, value]) => {
                            client.setRequestHeader(key, value);
                          },
                        );
                        client.onreadystatechange = () => {
                          switch (client.readyState) {
                            case 4:
                              dispatch(setNotice('Starting Download.'));
                              const windowUrl = window.URL.createObjectURL(
                                client.response,
                              );
                              const link = document.createElement('a');
                              link.href = windowUrl;
                              link.setAttribute('download', filename);
                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);
                              break;
                            default:
                          }
                        };
                        client.send();
                      }}
                    >
                      <FontAwesomeIcon icon={['fal', 'file-download']} />
                      <Tooltip text="Download file" align="center" />
                    </button>
                  )}
                  <button
                    disabled={!box_file_id && !preview && !linkUrl}
                    onClick={e => {
                      if (linkUrl) {
                        window.open(linkUrl, '_blank');
                      } else if (accessCode) {
                        const newUrl = downloadableUrl || filePath.url;
                        var client = new XMLHttpRequest();
                        client.open('GET', newUrl);
                        client.withCredentials = true;
                        client.responseType = 'blob';
                        Object.entries(requestHeaders(!downloadableUrl)).forEach(
                          ([key, value]) => {
                            client.setRequestHeader(key, value);
                          },
                        );
                        client.onreadystatechange = () => {
                          switch (client.readyState) {
                            case 4:
                              const windowUrl = window.URL.createObjectURL(
                                client.response,
                              );
                              const link = document.createElement('a');
                              link.href = windowUrl;
                              link.target = '_blank';
                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);
                              break;
                            default:
                          }
                        };
                        client.send();
                      } else if (!preview) {
                        dispatch(getBoxFileInfo(box_file_id)).then(payload => {
                          const win = window.open(
                            handlePath(
                              `${brandMeta.web_base_url}/cabinet/${get(
                                payload,
                                'parent.id',
                                -1,
                              )}/${box_file_id}`,
                              companyId,
                            ),
                            '_blank',
                          );
                          win.focus();
                        });
                      } else {
                        const win = window.open(preview, '_blank');
                        win.focus();
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={['fal', 'external-link']} />
                    <Tooltip text="Open in new tab" align="center" />
                  </button>
                  {numPages > 1 && (
                    <button
                      type="button"
                      disabled={pageNumber >= numPages}
                      onClick={e => {
                        setPageNumber(pageNumber + 1);
                      }}
                      aria-label="Next page"
                    >
                      <FontAwesomeIcon icon="chevron-right" />
                    </button>
                  )}
                </div>
              </Document>
            )}
          </div>
        </MyModal>
      )}
    </>
  );
};

export default OverviewCard;
