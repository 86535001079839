export default {
  'certificate-of-Incorporation': {
    path: '/workbench/2/1/-1/new',
    title: 'Certificate of Formation',
    label: 'Found in Entity Setup Workflow',
  },
  'certificate-of-formation': {
    path: '/workbench/2/1/-1/new',
    title: 'Certificate of Formation',
    label: 'Found in Entity Setup Workflow',
  },
  'operating-agreement': {
    path: '/workbench/2/1/-1/new',
    title: 'Operating Agreement',
    label: 'Found in Entity Setup Workflow',
  },
  'organizational-board-consent': {
    path: '/workbench/2/1/-1/new',
    title: 'Organizational Board Consent',
    label: 'Found in Entity Setup Workflow',
  },
  'stock-option-plan': {
    path: '/workbench/9/4/-1/new',
    title: 'Stock Option Plan',
    label: 'Found in Stock Option Plan Workflow',
  },
  'client-onboarding-rams': {
    path: '/workbench/2/216/0/new',
    title: 'Rams Client Onboarding',
    label: 'Found in the Client Onboarding Workflow',
  },
};
