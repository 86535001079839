import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { getBoxToken } from '../../redux/modules/Cabinet/operations';
import { BoxToken } from '../../redux/modules/Cabinet/selectors';

import { getAllDocuments } from '../../redux/modules/Document/operations';
import { setNotice } from '../../redux/modules/UI/actions';
import { getActionableDocuments } from '../../redux/modules/Document/selectors';
import { getTodos } from '../../redux/modules/Todos/operations';
import { TodosForHome } from '../../redux/modules/Todos/selectors';
import { getProjects } from '../../redux/modules/Project/operations';
import { getAccountTransactions } from '../../redux/modules/Transaction/operations';
import { getAccountMercuryBanks } from '../../redux/modules/Billing/operations';
import { getMissingInfoTransactions } from '../../redux/modules/Transaction/selectors';
import { AccountMercuryBanks } from '../../redux/modules/Billing/selectors';
import { someValuesEveryString } from '../../utils/FilterTools';
import handlePath from '../../utils/handlePath';
import Home from './Home';

const HomeContainer = ({ moduleId }) => {
  const { push } = useHistory();
  const location = useLocation();
  const { hash, search, state } = location;
  const { background, isPlansShowing } = state || {};
  const { companyId } = useParams();
  const dispatch = useDispatch();

  const boxToken = useSelector(BoxToken);
  const openTasks = useSelector(State =>
    getMissingInfoTransactions(State.Transaction, moduleId),
  );
  const mercuryBanks = useSelector(AccountMercuryBanks);
  const mercuryBank = mercuryBanks[0] || {};
  const { status, referral_link } = mercuryBank;
  const actionableDocs = useSelector(getActionableDocuments);
  const todos = useSelector(TodosForHome);

  const [minLoadingTimedOut, setMinLoadingTimedOut] = useState(false);
  const [maxLoadingTimedOut, setMaxLoadingTimedOut] = useState(false);

  const [initialized, setInitialized] = useState(!!state?.initialized);
  const [filterInput, setFilterInput] = useState(state?.filterInput || '');

  const myDocuments = useMemo(
    () => actionableDocs.filter(doc => someValuesEveryString(doc, [filterInput])),
    [actionableDocs, filterInput],
  );
  const myTasks = useMemo(
    () => openTasks.filter(doc => someValuesEveryString(doc, [filterInput])),
    [openTasks, filterInput],
  );
  const myTodos = useMemo(
    () => todos.filter(doc => someValuesEveryString(doc, [filterInput])),
    [todos, filterInput],
  );

  useEffect(() => {
    const minLoadingTimeout = setTimeout(() => setMinLoadingTimedOut(true), 300);
    const maxLoadingTimeout = setTimeout(() => setMaxLoadingTimedOut(true), 1500);
    return () => {
      clearTimeout(minLoadingTimeout);
      clearTimeout(maxLoadingTimeout);
    };
  }, []);

  useEffect(() => {
    const redirect = new URLSearchParams(search).get('redirect');
    if (redirect) {
      return push(redirect + hash);
    }
    if (isPlansShowing && background) {
      push(
        handlePath(
          {
            pathname: '/plans',
            hash,
            search,
            state: { background: location },
          },
          companyId,
        ),
      );
    }
  }, [background, companyId, hash, location, isPlansShowing, push, search]);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      dispatch(getTodos());
      dispatch(getAllDocuments());
      dispatch(getAccountTransactions(moduleId));
      dispatch(getProjects());
      if (!boxToken) {
        dispatch(getBoxToken());
      }
      if (mercuryBanks.length === 0) {
        dispatch(getAccountMercuryBanks());
      }
    }
    window.history.replaceState({}, document.title); // Resets location state for filters
  }, [
    boxToken,
    dispatch,
    actionableDocs.length,
    initialized,
    moduleId,
    mercuryBanks.length,
  ]);

  const isAllEmpty =
    myTasks.length === 0 && myDocuments.length === 0 && myTodos.length === 0;

  const isDataLoaded = (minLoadingTimedOut && !isAllEmpty) || maxLoadingTimedOut;
  const isMercuryBankShowing = !!status && status !== 'approved' && status !== 'rejected';

  const homeNavLinkItems = [
    {
      id: 'actionable_documents',
      isItemLengthShowing: true,
      itemLength: myDocuments.length,
      label: 'Documents Requiring Actions',
      path: {
        pathname: '/home',
        hash: '#actionable-documents',
      },
    },
    {
      id: 'to_dos',
      isItemLengthShowing: true,
      itemLength: myTodos.length,
      label: 'To-dos',
      path: {
        pathname: '/home',
        hash: '#to-dos',
      },
    },
    {
      id: 'open_tasks',
      isItemLengthShowing: true,
      itemLength: myTasks.length,
      label: 'Open Tasks',
      path: {
        pathname: '/home',
        hash: '#open-tasks',
      },
    },
  ];

  const handleCopyBankLink = ev => {
    ev.preventDefault();
    navigator.clipboard.writeText(referral_link);
    dispatch(setNotice('Link copied'));
  };

  return (
    <Home
      companyId={companyId}
      mercuryBank={mercuryBank}
      isMercuryBankShowing={isMercuryBankShowing}
      myDocuments={myDocuments}
      hash={hash}
      handleCopyBankLink={handleCopyBankLink}
      homeNavLinkItems={homeNavLinkItems}
      isAllEmpty={isAllEmpty}
      isDataLoaded={isDataLoaded}
      initialized={initialized}
      moduleId={moduleId}
      filterInput={filterInput}
      myTodos={myTodos}
      myTasks={myTasks}
      setFilterInput={setFilterInput}
    />
  );
};

export default HomeContainer;
