import React from 'react';
// import React, { useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
// import { Provider, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js/pure';
// import { loadStripe } from '@stripe/stripe-js';
import { ConnectedRouter } from 'connected-react-router';

import PropTypes from 'prop-types';

import messages from 'box-ui-elements/i18n/en-US';

import App from './App';
// import { StripeDomainSlug } from '../redux/modules/Billing/selectors';

const Root = ({ store, history }) => {
  // const stripePromise = useMemo(() => loadStripe(process.env.REACT_APP_STRIPE_KEY), []);

  return (
    <Provider store={store}>
      <IntlProvider locale="en-US" messages={messages}>
        <ConnectedRouter history={history}>
          <Route component={App} />
          {/* <RootInner /> */}
        </ConnectedRouter>
      </IntlProvider>
    </Provider>
  );
};

// const RootInner = () => {
//   const selectedStripeDomainSlug = useSelector(StripeDomainSlug);

//   const stripePromise = useMemo(() => {
//     return loadStripe(partnerStripeDict[selectedStripeDomainSlug][1]);
//   }, [selectedStripeDomainSlug]);

//   return (
//     <Elements stripe={stripePromise} key={`stripe-elements-${selectedStripeDomainSlug}`}>
//       <Route component={App} />
//     </Elements>
//   );
// };

Root.propTypes = {
  store: PropTypes.object.isRequired,
};
export default Root;
