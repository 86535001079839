import React, { useState } from 'react';

import './SoftRedirectBanner.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../Button';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(utc);
dayjs.extend(relativeTime);

const SoftRedirectBanner = () => {
  const [isShowing, setIsShowing] = useState(
    window.location.href.includes('app.savvi.legal') &&
      window.location.pathname.includes('login') &&
      !localStorage.getItem('isSoftRedirectHidden'),
  );
  const handleHide = () => {
    setIsShowing(false);
    localStorage.setItem('isSoftRedirectHidden', 1);
  };

  return (
    <div className={`softRedirectBanner${!isShowing ? ' softRedirectBanner--hide' : ''}`}>
      <FontAwesomeIcon className="softRedirectBanner__icon" icon="exclamation-circle" />
      <h4>
        app.savvi.legal will soon be redirected to app.paperos.com, this is because our
        company's primary brand is now{' '}
        <Button
          buttonType="link"
          size="sm"
          href="https://paperos.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          PaperOS
        </Button>
        . All data and features will be unchanged.
      </h4>
      <Button
        buttonType="icon"
        size="xs"
        className="softRedirectBanner__hide"
        onClick={handleHide}
      >
        <FontAwesomeIcon icon="times" />
      </Button>
    </div>
  );
};

export default SoftRedirectBanner;
