import React from 'react';

import get from 'lodash-es/get';

import InputStyledCheckbox from '../../common/InputStyledCheckbox';

import './StyledCheckbox.scss';

const StyledCheckbox = ({
  accountId,
  isDetailedOptions,
  isCreatable,
  isOutsideForm,
  exchangeToken,
  featureProps,
  feature_type_id,
  field: { name, value },
  flipLabel,
  form: { errors, setFieldValue, status, touched },
  handleDelete,
  handleFormBlur,
  handleResourceFormBlur,
  handleResourceName,
  handleRemoveResource,
  isDisabled,
  isLabel,
  isLocked,
  isModal,
  isNewResource,
  isPairedColumn,
  markdown,
  multiEntry,
  readOnly,
  reports_feature_type_id,
  resetValue,
  resource_id,
  resourceTypeName,
  setLabel,
  taskId,
  savedValue,
  roomId,
  ...props // everything that we should pass goes through here classnames etc
}) => {
  const apiError = get(status, name, false);
  const isTouched = get(touched, name, false);
  const error = apiError || (isTouched && get(errors, name, null));
  if (value === 'Yes') {
    value = true;
  } else if (value === 'No' || value === 'false' || value === '0') {
    value = false;
  }

  return (
    <InputStyledCheckbox
      isDisabled={isDisabled}
      onClick={e => {
        if (
          e.target.value === 'true' ||
          e.target.value === '1' ||
          e.target.value === 1 ||
          (typeof e.target.value === 'boolean' && !!e.target.value)
        ) {
          // weird timing here, makes it the opposite
          setFieldValue(name, '0');
          handleFormBlur({ target: { value: false } });
        } else {
          setFieldValue(name, '1');
          handleFormBlur({ target: { value: true } });
        }
      }}
      error={error}
      checked={!!value}
      labelStyle="form-styled-checkbox__label"
      parentStyle={isPairedColumn && 'styled-checkbox__paired-column'}
      value={value}
      name={name}
      {...props}
    />
  );
};

StyledCheckbox.defaultProps = {
  handleFormBlur: () => {},
};

export default StyledCheckbox;
