import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import startCase from 'lodash-es/startCase';

import Topbar from '../../components/ConsistentTopbar';
import SavviLoading from '../../components/SavviLoading/SavviLoading';
import Sidebar from '../Sidebar';
import handlePath from '../../utils/handlePath';
import Loading from '../../components/LoadingComponent';
// import UseWindowSize from '../../utils/UseWindowSize';

import './Workbench.scss';
import { Button } from '../../components/common';
import PortalView from '../PortalView/PortalView';
import PortalActionFormModal from '../../components/PortalActionFormModal';
import PortalTaskModal from '../../components/PortalTaskModal';
import UseWindowSize from '../../utils/UseWindowSize';
import BatchUploadModal from '../../components/BatchUploadModal/BatchUploadModal';
import { useSelector } from 'react-redux';
import { BrandMeta } from '../../redux/modules/UI/selectors';

const AsyncWorkbenchOverview = Loadable({
  loader: () => import('../WorkbenchOverview'),
  loading: Loading,
});
const AsyncWorkbenchTaskOverview = Loadable({
  loader: () => import('../WorkbenchTaskOverview'),
  loading: Loading,
});
const AsyncPlansModal = Loadable({
  loader: () => import('../../components/PlansModal'),
  loading: Loading,
});

// const AsyncPortalActionFormModal = Loadable({
//   loader: () => import('../../components/PortalActionFormModal'),
//   loading: Loading,
// });

const Workbench = ({
  accountInfo: { has_partner_subscription, credits_info, subscriptions = [] },
  handleExitTransaction,
  isFaqsEmpty,
  isFetchingInit,
  formikValues,
  location,
  params,
  isPortalView,
  transaction,
}) => {
  const { companyId, moduleId, projectId, transactionId } = params;

  const { width } = UseWindowSize();

  const brandMeta = useSelector(BrandMeta);

  const availableCredits = credits_info && credits_info.limit - credits_info.used;

  let isSubscription = false;
  let mySubscriptions = [];
  let isSubscribed = false;
  let documentCredits = 0;

  !isEmpty(transaction) &&
    transaction.tasks.forEach((e, index) => {
      if (!e.bypassed) {
        documentCredits += e.document_counter || 0;
      }
      const innerSubs = get(e, 'template.prerequisites.subscriptions', []);

      if (innerSubs.length > 0) {
        isSubscription = true;
        mySubscriptions = innerSubs;
        isSubscribed = mySubscriptions.find(
          sub =>
            subscriptions.findIndex(accountSub => {
              // advanced subscription covers starter subscription
              if (accountSub === 'advanced' && sub === 'starter') {
                return true;
              }
              return accountSub === sub;
            }) !== -1,
        );
      }
    });

  const isDocumentCreditsNeeded =
    availableCredits < 1 &&
    documentCredits > 0 &&
    !!transaction.open &&
    params.step !== 'overview' &&
    !get(transaction, 'template.is_assessment', false);

  const [isInfoSidebarCollapsed, setIsInfoSidebarCollapsed] = useState(
    isPortalView || width < 1080,
  );

  useEffect(() => {
    if (isPortalView) {
      setIsInfoSidebarCollapsed(true);
    }
  }, [isPortalView]);

  const background = location.state && location.state.background;

  if (isFetchingInit) {
    return (
      <div className="workbench__loader">
        <span>Loading...</span>
        <SavviLoading />
      </div>
    );
  }
  return (
    <div
      className={`workbench__container${
        isInfoSidebarCollapsed ? ' info-sidebar-collapsed' : ''
      }`}
    >
      <Sidebar
        isWorkbench
        isPortalView={isPortalView}
        workbenchParams={params}
        isAssessment={!!transaction?.template?.is_assessment && !!transaction.open}
      />
      <Topbar isWorkbench isPortalView={isPortalView} workbenchParams={params} />
      <div className={`workbench__main${isPortalView ? ' workbench__main--portal' : ''}`}>
        {isDocumentCreditsNeeded && !has_partner_subscription && (
          <div className="workbench__banner">
            <h4>
              <FontAwesomeIcon icon="exclamation-circle" />
              You currently do not have enough credits to submit this task. Increase your
              credit limit by{' '}
              <Link
                to={handlePath(
                  `/workbench/${moduleId}/${projectId}/${transactionId}/plans`,
                  companyId,
                )}
              >
                Upgrading your plan
              </Link>
            </h4>
          </div>
        )}
        {isDocumentCreditsNeeded && !!has_partner_subscription && (
          <div className="workbench__banner">
            <h4>
              <FontAwesomeIcon icon="exclamation-circle" />
              You currently do not have enough credits to submit this task. Please contact
              us at{' '}
              <Button
                buttonType="link"
                size="xs"
                href={`mailto:${brandMeta?.support_email || 'support@paperos.com'}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {brandMeta.support_email || 'support@paperos.com'}
              </Button>
              with any questions around increasing your credit limit.
            </h4>
          </div>
        )}
        {isSubscription &&
          !isSubscribed &&
          !!transaction.open &&
          !transaction.template.is_assessment && (
            <div
              className={`workbench__banner${isDocumentCreditsNeeded ? ' second' : ''}`}
            >
              <h4>
                <FontAwesomeIcon icon="exclamation-circle" />
                {startCase(mySubscriptions.join(', '))} Subscription or higher required to
                complete this task.{' '}
                {!has_partner_subscription && (
                  <Link
                    to={handlePath(
                      {
                        pathname: `/plans`,
                        state: { background: location },
                      },
                      companyId,
                    )}
                  >
                    Sign up here
                  </Link>
                )}
                {!!has_partner_subscription && (
                  <>
                    {'Please Contact us at '}
                    <Button
                      buttonType="link"
                      size="xs"
                      href={`mailto:${brandMeta?.support_email || 'support@paperos.com'}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {brandMeta.support_email || 'support@paperos.com'}
                    </Button>
                    {' about getting access.'}
                  </>
                )}
              </h4>
            </div>
          )}
        <Switch location={background || location}>
          <Route
            path={handlePath(
              `/workbench/${moduleId}/${projectId}/${transactionId}/overview`,
              companyId,
            )}
          >
            <AsyncWorkbenchOverview
              handleExitTransaction={handleExitTransaction}
              params={params}
            />
          </Route>
          <Route
            path={handlePath(
              `/workbench/${moduleId}/${projectId}/${transactionId}/portal`,
              companyId,
            )}
          >
            <PortalView params={params} />
          </Route>
          <Route
            exact
            path={handlePath(
              `/workbench/${moduleId}/${projectId}/${transactionId}/task-view`,
              companyId,
            )}
          >
            <AsyncWorkbenchTaskOverview
              documentCredits={documentCredits}
              formikValues={formikValues}
              isDocumentCreditsNeeded={isDocumentCreditsNeeded}
              isSubscriptionNeeded={isSubscription && !isSubscribed}
              isPortalView={isPortalView}
              params={params}
            />
          </Route>
        </Switch>
        <Route
          path={handlePath(
            `/workbench/${moduleId}/${projectId}/${transactionId}/plans`,
            companyId,
          )}
        >
          <AsyncPlansModal params={params} />
        </Route>
        <Route
          path={handlePath(
            `/workbench/${moduleId}/${projectId}/${transactionId}/batch-upload`,
            companyId,
          )}
        >
          <BatchUploadModal params={params} />
        </Route>
        <Route
          path={handlePath(
            `/workbench/${moduleId}/${projectId}/${transactionId}/add`,
            companyId,
          )}
        >
          <PortalActionFormModal params={params} />
        </Route>
        <Route
          path={handlePath(
            `/workbench/${moduleId}/${projectId}/${transactionId}/portal-task`,
            companyId,
          )}
        >
          <PortalTaskModal params={params} />
        </Route>
        {!isFaqsEmpty && params.step !== 'portal' && (
          <>
            <Button
              buttonType="icon"
              className="workbench__toggle-info"
              onClick={e => setIsInfoSidebarCollapsed(!isInfoSidebarCollapsed)}
            >
              <FontAwesomeIcon
                className="workbench__toggle-info-action"
                icon={isInfoSidebarCollapsed ? 'info' : ['fal', 'arrow-from-left']}
              />
            </Button>
            {!isInfoSidebarCollapsed && (
              <div
                onClick={e => setIsInfoSidebarCollapsed(true)}
                className="workbench__info-sidebar-overlay"
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

Workbench.propTypes = {
  isFetching: PropTypes.bool,
  formTitle: PropTypes.string,
  status: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  values: PropTypes.object,
};

Workbench.defaultProps = {
  status: {},
};

export default Workbench;
