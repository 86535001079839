import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { pdfjs } from 'react-pdf';

import { Button, NoticeToast, RedirectBanner } from '../components/common';
import Footer from '../components/Footer';
import Loading from '../components/LoadingComponent';
import { getDocuments, getUserDocuments } from '../redux/modules/Document/operations';
import {
  getTransaction,
  getTransactionDocuments,
} from '../redux/modules/Transaction/operations';
import { operations as userOps } from '../redux/modules/User';
import { fetchAccountRooms } from '../redux/modules/Data/operations';
import Basic from '../routes/Basic';
import Dashboard from '../routes/Dashboard';
import { userIsAuthenticated } from '../utils/Auth';
import OutsideForm from '../views/OutsideForm/OutsideFormContainer';
import Room from '../views/Room';
import Workbench from '../views/Workbench';
import { getStoredCompanyId } from '../redux/modules/User/utils';
import {
  getAccessToken,
  getAuthAccount,
  getAuthUser,
  IsAccountAuthenticated,
} from '../redux/modules/User/selectors';
import handlePath from '../utils/handlePath';
import VerifyCode from '../views/VerifyCode';
import VerifyByLink from '../views/VerifyByLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setNotice } from '../redux/modules/UI/actions';
import RoomInvite from '../views/RoomInvite';
import TermsOfServiceModal from '../components/TermsOfServiceModal';
import PrivacyPolicyModal from '../components/PrivacyPolicyModal';
// import PlansModal from '../components/PlansModal';
import NewCompany from '../views/NewCompany';
import Loadable from 'react-loadable';
import { fetchBrandMeta } from '../redux/modules/UI/operations';
import ReportingRoom from '../views/ReportingRoom/ReportingRoom';

const AuthWorkbench = userIsAuthenticated(Workbench);

const AsyncSubscriptionsModal = Loadable({
  loader: () => import('../components/PlansModal'),
  loading: Loading,
});

const unauthedRoutes = [
  'forgot-password',
  'login',
  'register',
  'reset-password',
  'plans',
  'remote-login',
];
const joinedUnauthedRoutes = unauthedRoutes.join('|');

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signoutTime: 1000 * 60 * 180,
      isVerifyHiding: localStorage.getItem('hide_verify'),
    };
    this.ws = null;
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  }
  componentDidMount() {
    const {
      history: { replace },
      // history: { push, replace },
      location,
      validateSession,
      fetchBrandMeta,
      setNotice,
    } = this.props;
    const { pathname, search = '', hash, state } = location;
    if (pathname.includes('workbench') && !pathname.includes('/c/')) {
      console.info(`replacing incorrect url, '${pathname}' with '/c/0/${pathname}'`);
      replace(handlePath(location, '0'));
    }

    // const { isInitialLoad } = state || {};

    const query = new URLSearchParams(search);
    const redirectFrom = query.get('redirectFrom');
    const passedIdToken = query.get('id_token');
    const storedCompanyId = getStoredCompanyId();
    const redirectLoc = localStorage.getItem('redirectLoc');
    const redirectHash = localStorage.getItem('redirectHash');
    let isPathChanged = false;

    fetchBrandMeta();
    if (redirectFrom) {
      localStorage.setItem('redirectFrom', redirectFrom);
    }

    if (!pathname || pathname === '/' || pathname === '/home') {
      if (storedCompanyId && storedCompanyId !== '0') {
        isPathChanged = true;
        replace(handlePath('/home', storedCompanyId));
      } else if (redirectLoc) {
        localStorage.removeItem('redirectLoc');
        localStorage.removeItem('redirectHash');
        if (redirectLoc.includes('data-room')) {
          isPathChanged = true;
          replace(redirectLoc + (redirectHash || ''));
        } else {
          isPathChanged = true;
          replace({
            pathname: '/login',
            search: redirectLoc + (redirectHash || ''),
          });
        }
      } else {
        isPathChanged = true;
        replace({ pathname: '/login', search, hash });
      }
    } else if (pathname.includes('/c/') && pathname.split('/')[2] === 'undefined') {
      isPathChanged = true;
      let updatedPathname = pathname.split('/');
      updatedPathname[2] = '0';
      updatedPathname = updatedPathname.join('/');
      replace({
        pathname: '/c/0/company-select',
        search: `?redirect=${updatedPathname}${search}`,
        hash,
      });
    } else if (
      !joinedUnauthedRoutes.includes(pathname.split('/')[1]) &&
      [
        '/c/',
        '/outside-form',
        '/invites',
        '/data-room',
        '/verify',
        '/terms',
        '/privacy',
        '/',
      ].findIndex(str => pathname.includes(str)) === -1
    ) {
      if (!storedCompanyId || storedCompanyId !== '0') {
        isPathChanged = true;
        replace({
          pathname: '/c/0/company-select',
          search: `?redirect=/c/0${pathname}${search}`,
          hash,
        });
      } else {
        isPathChanged = true;
        replace({
          pathname: '/login',
          search: `?redirect=/c/0${pathname}${search}`,
          hash,
        });
      }
    }
    validateSession(passedIdToken).then(
      p => {
        if (
          p.isCompaniesEmpty &&
          !isPathChanged &&
          [
            '/teams',
            '/dashboard',
            '/data-room',
            '/lp-portal',
            '/reporting-room',
            '/verify',
            '/user-settings',
          ].findIndex(e => pathname.includes(e)) === -1
        ) {
          if (p.isLPPortalShowing) {
            setNotice('No Workspaces found, redirecting to LP Portal.');
            replace({ pathname: '/c/0/lp-portal', search, hash, state });
          } else if (!p.isDashboardEmpty) {
            setNotice('No Workspaces found, redirecting to User Dashboard.');
            replace({ pathname: '/c/0/dashboard/personal', search, hash, state });
          }
          // } else if (isInitialLoad && !localStorage.getItem('hasNewCompanyModalShown')) {
          //   localStorage.setItem('hasNewCompanyModalShown', true);
          //   push({
          //     pathname: '/new-company',
          //     hash,
          //     search,
          //     state: { ...state, background: location },
          //   });
          // }
        }
      },
      err => {},
    );

    Modal.setAppElement('#root');
    /** ** Auth Refresh ** */
    this.events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
    this.logoutTimeout = null;
    this.warnTimeout = null;
  }

  componentDidUpdate(prevProps) {
    const { accountInfo, getAccountRooms, idToken, isAccountAuthenticated, location } =
      this.props;
    const { pathname } = location;
    if (!pathname.includes('/login') && sessionStorage.getItem('ghl_attempts')) {
      sessionStorage.removeItem('ghl_attempts');
    }
    if (pathname === '/c/0/company-select') {
      // redirect for google
    }
    // Will scroll to the top of the page after every route change.
    if (pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
    if (
      prevProps.isAccountAuthenticated !== isAccountAuthenticated &&
      isAccountAuthenticated
    ) {
      const { REACT_APP_PROXY_URL: proxyUrl } = process.env;
      this.ws = new WebSocket(
        proxyUrl.replace(/^http/, 'ws') + '/api/ws?access_token=' + idToken,
      );
      this.ws.addEventListener('message', ev => {
        const data = JSON.parse(ev.data);
        console.info('Websocket response', data);
        if (data.event === 'console') {
          console.info(data.message);
        }
        if (data.event === 'alert') {
          window.alert(data.message);
        }
        if (data.event === 'toast') {
          setNotice({ type: data.type, message: data.message });
        }
        if (data.event === 'refresh_documents') {
          this.handleRefreshDocument(data.message);
        }
        if (data.event === 'refresh_timeline') {
          this.handleRefreshTimeline(data.message);
        }
      });
      this.ws.addEventListener('error', error => {
        console.info('Websocket error response', error);
      });
      /** ** Auth Refresh ** */
      for (var i in this.events) {
        window.addEventListener(this.events[i], this.resetTimeout);
      }
      this.setTimeout();
    }
    if (
      prevProps.location.pathname.includes('data-room') &&
      !pathname.includes('data-room') &&
      isAccountAuthenticated &&
      accountInfo.accountId
    ) {
      getAccountRooms(accountInfo.accountId);
    }
  }

  handleRefreshDocument = data => {
    const {
      getDocuments,
      getUserDocuments,
      getTransactionDocuments,
      location: { pathname },
    } = this.props;
    if (
      ((pathname.includes('task-view') || pathname.includes('overview')) &&
        pathname.includes(data.transaction_id)) ||
      pathname.includes('home')
    ) {
      setTimeout(() => {
        getTransactionDocuments(data.transaction_id);
        getTransaction(data.transaction_id);
      }, 3500);
    } else if (pathname.includes('home')) {
      setTimeout(() => getDocuments(), 3500);
    } else if (pathname.includes('dashboard') || pathname.includes('lp-portal')) {
      this.setTimeout(() => getUserDocuments(), 2000);
    }
  };

  handleRefreshTimeline = data => {
    const {
      getTransaction,
      location: { pathname },
    } = this.props;
    if (
      (pathname.includes('task-view') || pathname.includes('overview')) &&
      pathname.includes(data.transaction_id)
    ) {
      getTransaction(data.transaction_id);
    }
  };

  /** ** Auth Refresh ** */
  clearTimeoutFunc = () => {
    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  };

  /** ** Auth Refresh ** */
  setTimeout = () => {
    this.warnTimeout = setTimeout(this.warn, this.state.warningTime);
    this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
  };

  /** ** Auth Refresh ** */
  resetTimeout = () => {
    this.clearTimeoutFunc();
    this.setTimeout();
  };

  logout = () => {
    // Send a logout request to the API
    window.alert(
      'You have been logged out due to inactivity. Please login again. Any progress will have been saved.',
    );
    console.info('Sending a logout request to the API...');
    this.props.logout();
  };

  render() {
    const {
      location,
      user: { isAuthenticated, email_verified },
    } = this.props;
    const { search, pathname, state } = location;
    const { redirectPathname } = state || {};
    const { isVerifyHiding } = this.state;

    const isTeamView =
      ['/company-select', '/teams', '/dashboard', '/user-settings'].findIndex(e =>
        pathname.includes(e),
      ) !== -1;

    const background = location.state && location.state.background;

    return (
      <div className="app-container">
        {/* <SiteMaintenanceBanner
          timeStartUtc="2024-09-01T19:00:00Z"
          timeEndUtc="2024-09-01T20:00:00Z"
        /> */}
        {/* <BoxIsDownBanner /> */}
        <RedirectBanner />
        {/* <SoftRedirectBanner /> */}
        {isAuthenticated &&
          !isVerifyHiding &&
          !email_verified &&
          ![
            ...unauthedRoutes,
            'verify-code',
            'verify',
            'data-room',
            'outside-form',
          ].includes(pathname.split('/')[1]) && (
            <div className="app-verify">
              <FontAwesomeIcon className="app-verify__icon" icon="exclamation-circle" />
              <h4>
                For safety and security purposes, please
                <Button
                  buttonType="link"
                  to={{
                    pathname: '/verify-code/verification-email',
                    state: { redirectLocation: pathname },
                  }}
                >
                  verify your account
                </Button>
              </h4>
              <Button
                buttonType="icon"
                size="xs"
                className="app-verify__hide"
                onClick={() => {
                  localStorage.setItem('hide_verify', true);
                  this.setState({ isVerifyHiding: true });
                }}
              >
                <FontAwesomeIcon icon="times" />
              </Button>
            </div>
          )}
        {search.includes('redirect') && (
          <div className={`app-redirect ${isTeamView ? '' : 'login'}`}>
            <FontAwesomeIcon className="app-verify__icon" icon="exclamation-circle" />
            <h4>
              {isTeamView
                ? 'Before continuing, please select a company.'
                : 'Before continuing, please login or register below.'}
            </h4>
          </div>
        )}
        {!!redirectPathname && (
          <div className="app-redirect login">
            <FontAwesomeIcon className="app-verify__icon" icon="exclamation-circle" />
            <h4>
              {redirectPathname.includes('remote-login')
                ? 'To remotely login please first log in as a PaperOS Admin user.'
                : 'You will be directed back to the Deal Room once you login or register.'}
            </h4>
          </div>
        )}
        <Switch location={background || location}>
          <Route path="/loading" component={Loading} />
          <Route
            path="/c/:companyId/workbench/:moduleId/:projectId/:transactionId/:step"
            component={AuthWorkbench}
          />
          <Route path="/outside-form/:access_code" component={OutsideForm} />
          <Route path="/invites/room/:access_code">
            <RoomInvite />
          </Route>
          <Route path="/verify-code/:template_key">
            <VerifyCode
              hideVerify={() => {
                localStorage.setItem('hide_verify', true);
                this.setState({ isVerifyHiding: true });
              }}
            />
          </Route>
          <Route path="/verify/:template_key/:verification_id/:verification_code">
            <VerifyByLink
              hideVerify={() => {
                localStorage.setItem('hide_verify', true);
                this.setState({ isVerifyHiding: true });
              }}
            />
          </Route>
          <Route path="/data-room/v1/:room_access_code" component={Room} />
          <Route path="/data-room/:room_access_code" component={ReportingRoom} />
          <Route path={`/(${joinedUnauthedRoutes})`} component={Basic} />
          <Route path="/c/:companyId" component={userIsAuthenticated(Dashboard)} />
          {/* <Redirect from="/" to="/login" /> */}
        </Switch>
        <Route path="/terms" component={TermsOfServiceModal} />
        <Route path="/privacy" component={PrivacyPolicyModal} />
        <Route path={'/c/:companyId/plans'} component={AsyncSubscriptionsModal} />
        <Route path={'/new-company'} component={NewCompany} />
        <Route component={Footer} />
        <NoticeToast />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAccountAuthenticated: IsAccountAuthenticated(state),
    accountInfo: getAuthAccount(state),
    idToken: getAccessToken(state),
    user: getAuthUser(state),
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    history: { push },
    match: {
      params: { companyId },
    },
  },
) => ({
  fetchBrandMeta: id => dispatch(fetchBrandMeta(id)),
  getDocuments: id => dispatch(getDocuments(id)),
  getUserDocuments: id => dispatch(getUserDocuments(id)),
  getAccountRooms: accountId => dispatch(fetchAccountRooms(accountId)),
  getTransaction: id => dispatch(getTransaction(id)),
  getTransactionDocuments: id => dispatch(getTransactionDocuments(id)),
  validateSession: passedTokenId =>
    dispatch(userOps.validateSession(companyId, true, passedTokenId)),
  logout: () => dispatch(userOps.onLogout(push)),
  setNotice: message => dispatch(setNotice(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
