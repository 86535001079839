import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik } from 'formik';
import { ReactComponent as EditFileSVG } from '../../assets/images/file_edit.svg';
import { ReactComponent as UploadFileSVG } from '../../assets/images/file_upload.svg';

import FeatureTypes from '../../utils/FeatureTypes';
import { Button, MyModal } from '../common';

import './AddResourceModal.scss';
import {
  createResourceName,
  getTransactionQuestionnaire,
  updateResourceName,
} from '../../redux/modules/Transaction/operations';
import {
  patchFeature,
  getFlatFeatures,
  updateResourceFeatures,
  fetchResourcesByTypeId,
  deleteIndividual,
  fetchResourceId,
} from '../../redux/modules/Formation/operations';
import {
  getAllDocuments,
  addDocumentRecipient,
} from '../../redux/modules/Document/operations';
import { fetchCompanyDataTables } from '../../redux/modules/Company/operations';
import { get } from 'lodash-es';
import {
  getFlatFeaturesForOptions,
  getResourcesByTypeIdForSelect,
} from '../../redux/modules/Formation/selectors';
import { ResourceSelect } from '../formik';
import { handleExternalUrlWithCompanyId } from '../../utils/handlePath';
import classNames from 'classnames';
import { massageResource } from '../DataTable/DataTable';
import { fetchRoomByAccessCode } from '../../redux/modules/Data/operations';
import { setNotice } from '../../redux/modules/UI/actions';

const initNewResourceVals = (resource, isResourceEmpty) => {
  let formikValues = {};
  (resource.resourceItems || []).forEach(e => {
    formikValues[e.feature_type.name] = isResourceEmpty ? e.defaultValue : e.initialValue;
  });
  (resource.documentItems || []).forEach(e => {
    formikValues[e.feature_type.name] = isResourceEmpty ? e.defaultValue : e.initialValue;
  });
  return formikValues;
};

const AddResourceModal = ({
  accountId,
  accessCode,
  documentsTotal,
  requiredDocumentsTotal,
  handleClose: passedHandleClose,
  isCompany,
  isLocked, // currently only for data room & outside form
  isNew,
  isOpen,
  json,
  params = {},
  readOnly,
  reports_feature_type_id,
  resource,
  resourceTypeId,
  roomId,
  setSelectedResource,
  setIsNew,
}) => {
  const {
    allow_new,
    feature_types = [],
    links = [],
    resource_label,
    resource_type,
    resource_type_id,
  } = json;
  const {
    documentItems = [],
    documentsCompleted,
    isDeletable,
    isDocsShowingInitially,
    requiredDocumentsCompleted,
    resourceId,
    resourceItems = [],
    resourceName,
  } = resource || {};

  const dispatch = useDispatch();

  const [isOpenInit, setIsOpenInit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [docsVisible, setDocsVisible] = useState(false);
  const [isAddView, setIsAddView] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isMaxClosingTimeout, setIsMaxClosingTimeout] = useState(false);

  useEffect(() => {
    if (isOpen && isDocsShowingInitially) {
      setDocsVisible(true);
      setFormVisible(false);
    } else if (isOpen && !isDocsShowingInitially) {
      setDocsVisible(false);
      setFormVisible(true);
    }
  }, [isDocsShowingInitially, isOpen]);

  useEffect(() => {
    if (!isOpenInit && isOpen) {
      setIsOpenInit(true);
      if (isNew && resource_type_id) {
        dispatch(fetchResourcesByTypeId(resource_type_id, accountId));
        setIsAddView(links.length > 0);
      } else if (isCompany) {
        dispatch(
          getFlatFeatures(
            {
              select: [
                1, // individual > name
                9, // individual > is_board_director
              ],
              where: {
                9: '1', // individual > is_board_director
              },
            },
            'directors',
            accountId,
          ),
        );
      }
    }
  }, [
    accountId,
    isCompany,
    isOpen,
    isOpenInit,
    isNew,
    dispatch,
    links,
    resource_type_id,
  ]);

  const handleClose = useCallback(() => {
    setSelectedResource({});
    setIsAddView(false);
    setIsOpenInit(false);
    setIsSubmitting(false);
    setIsNew(false);
    setDocsVisible(false);
    setFormVisible(true);
    passedHandleClose();
    setIsClosing(false);
    setIsMaxClosingTimeout(false);
  }, [setIsNew, setSelectedResource, passedHandleClose]);

  useEffect(() => {
    if (isClosing) {
      const maxClosingTimeout = setTimeout(() => setIsMaxClosingTimeout(true), 5000);
      return () => {
        clearTimeout(maxClosingTimeout);
      };
    }
  }, [isClosing]);

  useEffect(() => {
    if (isMaxClosingTimeout) {
      dispatch(setNotice('Updates made will appear shortly.'));
      handleClose();
    }
  }, [dispatch, handleClose, isMaxClosingTimeout]);

  const handleClosingModal = () => {
    setIsClosing(true);
    if (!!accessCode) {
      return dispatch(fetchRoomByAccessCode(accessCode, accountId)).then(
        e => handleClose(),
        error => handleClose(),
      );
    }
    const finalAction = params.transactionId
      ? getTransactionQuestionnaire
      : fetchCompanyDataTables;
    return dispatch(finalAction(params.transactionId)).then(
      e => handleClose(),
      error => handleClose(),
    );
  };

  const handleDeleteResource = (passedResourceId, passedResourceName) => {
    const id = passedResourceId || resourceId;
    const name = passedResourceName || resourceName;
    const message = `Are you sure you want to remove ${name} from our system?`;

    if (window.confirm(message)) {
      dispatch(deleteIndividual(id, name, accountId)).then(e => {
        if (!!accessCode) {
          dispatch(fetchRoomByAccessCode(accessCode, accountId));
        }
        return !!passedResourceId
          ? dispatch(fetchResourcesByTypeId(resource_type_id, accountId))
          : handleClosingModal();
      });
    }
  };

  const handleSubmit = formikValues => {
    if (isLocked) {
      return handleClose();
    }
    const resource_name = formikValues[feature_types[0].feature_type.name];
    const emptyPromise = () => async () => {
      return await Promise.resolve();
    };
    let submitFunc = updateResourceName;
    if (resource_name === resource.resourceName || !allow_new) {
      submitFunc = emptyPromise;
    }

    setIsSubmitting(true);
    dispatch(
      submitFunc(
        {
          name: resource_name,
          resource_type_id,
        },
        resource.resourceId,
        false,
        accountId,
      ),
    ).then(
      async payload => {
        const name = formikValues?.name || '';
        const email = formikValues?.email || '';
        if (json.resource_type_id === 1 && !!email) {
          let docIds = [];
          for (const fieldType of json.feature_types) {
            if (
              fieldType.feature_type.feature_type === 'document' &&
              !!formikValues[fieldType.feature_type.name]
            ) {
              docIds.push(formikValues[fieldType.feature_type.name]);
            }
          }
          if (docIds.length > 0) {
            await dispatch(getAllDocuments()).then(async docs => {
              for (const docId of docIds) {
                const docIndex = docs.findIndex(p => p.box_file_id === docId);
                if (docIndex !== -1) {
                  const doc = docs[docIndex];
                  const isNotRecipient =
                    (doc?.recipients || []).findIndex(r => r.email === email) === -1;
                  const isNotEmailRecipient =
                    (doc?.email_recipients || []).findIndex(r => r.email === email) ===
                    -1;

                  if (isNotRecipient && isNotEmailRecipient) {
                    await dispatch(
                      addDocumentRecipient({
                        email,
                        document_id: doc.id,
                        metadata: { name, role: 'Signer' },
                      }),
                    );
                  }
                }
              }
              return await docs;
            });
          }
        }
        await dispatch(
          updateResourceFeatures(
            formikValues,
            resource.resourceItems,
            feature_types,
            (payload && payload.id) || resource.resourceId,
            resource_name,
            isCompany,
            isNew,
            accountId,
          ),
        ).then(handleClosingModal, handleClosingModal);
      },
      error => handleClosingModal(),
    );
  };

  let modalHeader =
    (isNew &&
      `Add${
        ['Director', 'Employee'].includes(resource_label) ? '/Remove' : ''
      } a(n) ${resource_label}`) ||
    resourceName ||
    resource_label;

  let modalSubheader =
    (isAddView && `How would you like to add your ${resource_label}`) ||
    (!resourceName && `First add or select a new ${resource_label}`) ||
    (!isAddView && !resourceName && `First add or select a new ${resource_label}`) ||
    resource_label;

  const requiredDocsCompletedLabel = `${requiredDocumentsCompleted} of ${requiredDocumentsTotal} required documents uploaded`;
  let documentsLabel = `Documents (${documentsTotal})`;
  const isAsterikShowing = !!isNew && !isAddView;
  const isLinksSublabelShowing = isAddView && links.length > 0 && !isLocked;
  const isFormSublabelShowing = links.length > 0 && !isLocked;
  const formSublabel = `Certain fields/documents can only be ${
    resourceName ? 'adjusted' : 'created'
  } through linked workflow(s) below.`;
  const isDocumentsAvailable =
    (!accessCode && !!documentsTotal) || (!!accessCode && documentsCompleted > 0);
  const isDeleteActionShowing =
    !!isDeletable && !isNew && !accessCode && resourceTypeId !== 2;

  return (
    <MyModal
      className="addResourceModal"
      overlayClassName="addResourceModal__overlay"
      isOpen={isOpen}
      onRequestClose={handleClose}
    >
      <div className="addResourceModal__top">
        <h1>
          {modalHeader}
          {isAsterikShowing && <span>*</span>}
          {isDeleteActionShowing && (
            <Button buttonType="icon" isWarning onClick={() => handleDeleteResource()}>
              <FontAwesomeIcon icon={['fal', 'trash-alt']} />
            </Button>
          )}
        </h1>
        <h4 className="addResourceModal__subheader">{modalSubheader}</h4>
        {isAsterikShowing && (
          <h4 className="addResourceModal__subheader">
            Inputs marked with '<span>*</span>' are required.
          </h4>
        )}
        <FontAwesomeIcon
          className="addResourceModal__exit"
          onClick={handleClose}
          icon={['fal', 'times']}
        />
        {isLinksSublabelShowing && (
          <h4 className="addResourceModal__addActionSublabel">
            <FontAwesomeIcon
              className="addResourceModal__warning"
              icon="exclamation-circle"
            />
            Certain fields/documents can only be created through linked workflow(s) below.
          </h4>
        )}
      </div>
      {isAddView && (
        <div className="addResourceModal__addActions">
          {links.map((link, ind) => (
            <a
              key={`add-resource-options-${ind}`}
              className="addResourceModal__addAction"
              target="_blank"
              rel="noopener noreferrer"
              href={handleExternalUrlWithCompanyId(link.link_url, params.companyId)}
            >
              <EditFileSVG />
              <div className="addResourceModal__addActionText">
                <h2 className="addResourceModal__subheader" key={`resource-link-${ind}`}>
                  Start Workflow
                </h2>
                <h4>{link.label}</h4>
              </div>
            </a>
          ))}
          {!!allow_new && (
            <div
              className="addResourceModal__addAction"
              onClick={e => {
                setFormVisible(true);
                setIsAddView(false);
              }}
            >
              <UploadFileSVG />
              <div className="addResourceModal__addActionText">
                <h2 className="addResourceModal__subheader">
                  Manual Add {resource_type}
                </h2>
                <h4>Add existing {resource_type} and upload any connected documents</h4>
              </div>
            </div>
          )}
          {isCompany && (
            <div
              className="addResourceModal__addAction"
              onClick={e => {
                setFormVisible(true);
                setIsAddView(false);
              }}
            >
              <UploadFileSVG />
              <div className="addResourceModal__addActionText">
                <h2 className="addResourceModal__subheader">
                  Edit {resource_type} details
                </h2>
                <h4>Add existing {resource_type} and upload any connected documents</h4>
              </div>
            </div>
          )}
        </div>
      )}
      {!isAddView && (
        <Formik
          initialValues={initNewResourceVals(resource, !resourceName)}
          onSubmit={handleSubmit}
        >
          {formikProps => (
            <Form>
              <div className="addResourceModal__formHeaderWrapper">
                <div
                  className={classNames('addResourceModal__formHeader', {
                    'addResourceModal__formHeader--collapsed': !formVisible,
                  })}
                  onClick={e => setFormVisible(!formVisible)}
                >
                  <FontAwesomeIcon
                    className="addResourceModal__formHeaderIcon"
                    icon={['fal', 'chevron-down']}
                  />
                  <h2>Information</h2>
                  {isNew && (
                    <h4>
                      Inputs marked with "<span>*</span>" are required
                    </h4>
                  )}
                  {isFormSublabelShowing && (
                    <h4 className="addResourceModal__subheader">
                      <FontAwesomeIcon
                        className="addResourceModal__warning"
                        icon="exclamation-circle"
                      />
                      {formSublabel}
                    </h4>
                  )}
                </div>
              </div>
              <div
                className={classNames('addResourceModal__formContent', {
                  'addResourceModal__formContent--visible': formVisible,
                  'addResourceModal__formContent--borderBottom': documentItems.length > 0,
                })}
              >
                {resourceItems
                  .filter(
                    e =>
                      e.edit_view &&
                      !e.isDocument &&
                      (!isLocked ||
                        (isLocked &&
                          e.feature.value &&
                          e.feature_type.feature_type !== 'boolean')),
                  )
                  .sort((a, b) => {
                    if (
                      b.feature_type.feature_type === 'address' ||
                      b.feature_type.feature_type === 'address_universal'
                    ) {
                      return -1;
                    }
                    return 0;
                  })
                  .map((item, index) => (
                    <ResourceItem
                      key={`resource-feature-${index}-${item.feature_type.feature_type}`}
                      accessCode={accessCode}
                      accountId={accountId}
                      formikProps={formikProps}
                      handleDeleteResource={handleDeleteResource}
                      index={index}
                      item={item}
                      isLocked={isLocked}
                      isNew={isNew}
                      json={json}
                      params={{ ...params, access_code: accessCode }}
                      reports_feature_type_id={reports_feature_type_id}
                      resource={resource}
                      setIsNew={setIsNew}
                      setSelectedResource={setSelectedResource}
                    />
                  ))}
              </div>
              {isDocumentsAvailable && (
                <div className="addResourceModal__formHeaderWrapper">
                  <div
                    className={classNames('addResourceModal__formHeader', {
                      'addResourceModal__formHeader--collapsed': !docsVisible,
                    })}
                    onClick={e => {
                      setDocsVisible(!docsVisible);
                    }}
                  >
                    <FontAwesomeIcon
                      className="addResourceModal__formHeaderIcon"
                      icon={['fal', 'chevron-down']}
                    />
                    <h2>{documentsLabel}</h2>
                    {!!requiredDocumentsTotal && <h4>{requiredDocsCompletedLabel}</h4>}
                    {!!isNew && (
                      <h4>
                        Documents marked with "<span>*</span>" are considered required
                        documents and something you should upload/generate as soon as
                        possible. You can still initially add{' '}
                        {resourceName || `this ${resource_label}`} without all required
                        documents.
                      </h4>
                    )}
                  </div>
                </div>
              )}
              <div
                className={classNames('addResourceModal__formContent', {
                  'addResourceModal__formContent--visible': docsVisible,
                })}
              >
                {documentItems
                  .filter(
                    e =>
                      e.edit_view &&
                      (!isLocked ||
                        (isLocked &&
                          e.feature.value &&
                          e.feature_type.feature_type !== 'boolean')),
                  )
                  .map((item, index) => (
                    <ResourceItem
                      key={`resource-feature-doc-${index}-${item.feature_type.feature_type}`}
                      accountId={accountId}
                      formikProps={formikProps}
                      index={index}
                      item={item}
                      isLocked={isLocked}
                      isNew={isNew}
                      json={json}
                      params={{ ...params, access_code: accessCode }}
                      readOnly={readOnly}
                      reports_feature_type_id={reports_feature_type_id}
                      resource={resource}
                      roomId={roomId}
                      setIsNew={setIsNew}
                      setSelectedResource={setSelectedResource}
                    />
                  ))}
              </div>
              <div className="addResourceModal__buttons">
                <div className="addResourceModal__buttonCol">
                  <Button
                    buttonType="secondary"
                    isDisabled={isSubmitting}
                    isFetching={isSubmitting}
                    onClick={handleClose}
                  >
                    {isLocked ? 'Close' : 'Cancel'}
                  </Button>
                  {links.length > 0 && !isLocked && (
                    <Button buttonType="link" size="sm" onClick={e => setIsAddView(true)}>
                      View Linked Workflow(s)
                    </Button>
                  )}
                </div>
                {!isLocked && (
                  <Button
                    isDisabled={isSubmitting}
                    isFetching={isSubmitting}
                    type="submit"
                  >
                    {isNew ? 'Save' : 'Assign'}
                  </Button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      )}
    </MyModal>
  );
};

AddResourceModal.propTypes = {
  accessCode: PropTypes.string,
  documentsTotal: PropTypes.number,
  requiredDocumentsTotal: PropTypes.number,
  handleClose: PropTypes.func,
  isCompany: PropTypes.bool,
  isLocked: PropTypes.bool, // currently only for data room & outside form
  isNew: PropTypes.bool,
  isOpen: PropTypes.bool,
  json: PropTypes.object,
  params: PropTypes.object,
  reports_feature_type_id: PropTypes.number,
  resource: PropTypes.object,
  setSelectedResource: PropTypes.func,
  setIsNew: PropTypes.func,
};

const ResourceItem = ({
  accessCode,
  accountId,
  formikProps,
  handleDeleteResource,
  index,
  isLocked,
  isNew,
  item,
  json,
  params,
  readOnly,
  reports_feature_type_id,
  resource,
  roomId,
  setIsNew,
  setSelectedResource,
}) => {
  const dispatch = useDispatch();
  const {
    allow_entry,
    feature_name,
    feature_type: {
      feature_type,
      filename,
      is_creatable,
      multi_entry_feature,
      name,
      options,
      path,
      question_text,
      question_comment,
      question_help,
      question_placeholder,
      tags,
    },
    feature_type_id,
    isDocument,
    new_require,
    required,
    feature,
  } = item;
  const isSignatory = name === 'company_signatory';
  const { feature_types = [], resource_type, resource_type_id } = json;
  const { resourceName, resourceId } = resource || {};

  const directors = useSelector(state => getFlatFeaturesForOptions(state, 'directors'));
  const resources = useSelector(state =>
    getResourcesByTypeIdForSelect(state, resource_type_id),
  ).sort((a, b) => {
    // Move drafts to the bottom
    if (a?.is_draft && !b?.is_draft) return 1;
    if (!a?.is_draft && b?.is_draft) return -1;

    // Sort alphabetically by label
    return (a.label || '').localeCompare(b.label || '');
  });

  const handleResourceName = (selectedResource, formikProps, isEditingName) => {
    const body = {
      resource_type_id,
      name: selectedResource?.label || selectedResource,
    };
    const passedResourceId = selectedResource?.id;
    body.transactionId = params.transactionId;
    if (!passedResourceId && !isEditingName) {
      return dispatch(createResourceName(body, accountId)).then(payload =>
        dispatch(fetchResourceId(payload.id, payload.resource_type_id, accountId)).then(
          e => {
            const updatedResource = massageResource(
              e[payload.id],
              feature_types,
              payload.id,
            );
            setSelectedResource(updatedResource);
            formikProps.resetForm({
              values: initNewResourceVals(updatedResource),
            });
            setIsNew(true);
          },
        ),
      );
    } else if (!isEditingName && passedResourceId !== resourceId) {
      dispatch(fetchResourceId(passedResourceId, body.resource_type_id, accountId)).then(
        e => {
          const updatedResource = massageResource(
            e[passedResourceId],
            feature_types,
            passedResourceId,
          );
          setSelectedResource(updatedResource);
          formikProps.resetForm({
            values: initNewResourceVals(updatedResource),
          });
        },
      );
    }
  };
  const handleFormBlur = e => {
    if (feature_type === 'document') {
      dispatch(
        patchFeature(
          {
            value: e.target.value,
            feature_type_id,
            resource_id: resourceId,
          },
          resourceName,
          name,
          true,
          accountId,
        ),
      );
    }
  };
  const handleRemoveResource = () => {
    const emptyResource = massageResource([], feature_types);
    setSelectedResource(emptyResource);
    formikProps.resetForm({
      values: initNewResourceVals(emptyResource, true),
    });
  };
  const validateField = val => {
    if (!isDocument && index !== 0 && ((isNew && new_require) || (!isNew && required))) {
      if (isSignatory) {
        return FeatureTypes['options'].validate(val);
      }
      return FeatureTypes[feature_type].validate(val);
    } else if (feature_type === 'date') {
      return FeatureTypes[feature_type].validate(
        val,
        true,
        get(formikProps, `touched.${name}`, false),
      );
    } else if (feature_type === 'email') {
      return FeatureTypes[feature_type].validate(val);
    }
  };

  let questionLabel = feature_type === 'boolean' ? question_text : feature_name;
  const isResourceName = index === 0 && !isDocument;
  let updatedOptions = options;
  if (isResourceName) {
    updatedOptions = resources;
  } else if (feature_type === 'detailed_options') {
    updatedOptions = JSON.parse(options);
  } else if (isSignatory) {
    updatedOptions = directors;
  }

  const FieldComponent = isResourceName
    ? ResourceSelect
    : FeatureTypes[isSignatory ? 'options' : feature_type].Formik;
  const isDisabled = !isDocument && !!isLocked && !isResourceName;
  const isFixed =
    (isNew && !resourceId && !isResourceName) || !!feature.fixed || !allow_entry;
  const isRequired =
    (!isNew && !!required) || (isNew && !!new_require) || feature_type === 'email';
  const placeholder = isResourceName
    ? 'Type to search or create new...'
    : question_placeholder;
  const reportsFeatureTypeId = !!params.access_code ? reports_feature_type_id : '';
  const isCreatable = isResourceName || !!is_creatable;

  return (
    <Field
      accountId={accountId}
      autoComplete="nope"
      component={FieldComponent}
      handleResourceName={(selectedResource, isEditingName) =>
        handleResourceName(selectedResource, formikProps, isEditingName)
      }
      handleDelete={handleDeleteResource}
      handleRemoveResource={handleRemoveResource}
      icon="user"
      isNewResource={isNew && !feature.value}
      name={name}
      exchangeToken={feature.exchange_token}
      feature_type={feature_type}
      feature_type_id={feature_type_id}
      filename={filename}
      handleFormBlur={handleFormBlur}
      isDisabled={isDisabled}
      isLocked={isLocked}
      isPairedColumn
      isCreatable={isCreatable}
      isDetailedOptions={feature_type === 'detailed_options'}
      isFixed={isFixed}
      isRequired={isRequired}
      label={questionLabel}
      multiEntry={multi_entry_feature}
      options={updatedOptions}
      setLabel={() => {}}
      params={params}
      path={path}
      placeholder={placeholder}
      questionHelp={question_help}
      readOnly={readOnly}
      resource_id={resourceId}
      resource_name={resourceName}
      resetValue={FeatureTypes[feature_type].value}
      resourceTypeName={resource_type}
      reports_feature_type_id={reportsFeatureTypeId}
      roomId={roomId}
      sublabel={question_comment}
      tags={tags}
      taskId={params.transactionId}
      validate={validateField}
    />
  );
};

ResourceItem.propTypes = {
  formikProps: PropTypes.object,
  index: PropTypes.number,
  isLocked: PropTypes.bool,
  isNew: PropTypes.bool,
  item: PropTypes.object,
  json: PropTypes.object,
  params: PropTypes.object,
  reports_feature_type_id: PropTypes.number,
  resource: PropTypes.object,
  setIsNew: PropTypes.func,
  setSelectedResource: PropTypes.func,
};

export default AddResourceModal;
