export default {
  paperos: ['PaperOS', process.env.REACT_APP_STRIPE_KEY],
  develop: ['Develop', process.env.REACT_APP_TEST_STRIPE_KEY],
  rams: [
    'Rams',
    ((window.location.href.includes('demo.rams') ||
      window.location.href.includes('localhost')) &&
      process.env.REACT_APP_RAMS_TEST_STRIPE_KEY) ||
      process.env.REACT_APP_RAMS_STRIPE_KEY,
  ],
};
