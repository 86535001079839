import React from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';

import isEmpty from 'lodash-es/isEmpty';
import startCase from 'lodash-es/startCase';

import Button from '../../components/common/Button';
import PrereqDict from '../../utils/PrereqDict';
import { useLocation } from 'react-router';

import './ProjectPrereq.scss';
import handlePath from '../../utils/handlePath';
import { MyModal } from '../../components/common';

const ProjectPrereq = ({ isOpen, handleCloseModal, moduleId, prereqMeta = [] }) => {
  const { companyId } = useParams();
  const location = useLocation();
  const customStyles = {
    overlay: {
      zIndex: 9,
      backgroundColor: 'rgba(0,0,0,.66)',
      position: 'fixed',
      height: '100vh',
      width: '100vw',
      display: 'flex',
    },
    content: {
      position: 'static',
      maxWidth: '80%',
      maxHeight: '80%',
      margin: 'auto',
    },
  };

  return (
    <MyModal isOpen={isOpen} onRequestClose={handleCloseModal} style={customStyles}>
      <div className="prereq-modal">
        {!isEmpty(prereqMeta.tasks) && (
          <div className="prereq__card">
            <h2>Prerequisites not met:</h2>
            {prereqMeta &&
              prereqMeta.tasks.map((prereq, ind) => {
                let prereqInfo = PrereqDict[prereq];
                if (!prereqInfo) {
                  prereqInfo = {
                    title: startCase(prereq),
                    label: 'Found in Entity Setup Workflow',
                    path: '/workbench/2/1/-1/new',
                  };
                }
                return (
                  <p key={`prereqs-${ind}`}>
                    - {prereqInfo.title} ({prereqInfo.label}{' '}
                    <Link to={handlePath(prereqInfo.path, companyId)}>here</Link>)
                  </p>
                );
              })}
          </div>
        )}
        {!isEmpty(prereqMeta.subscriptions) && (
          <div className="prereq__card">
            <h2>Subscriptions required:</h2>
            {prereqMeta.subscriptions.map((prereq, ind) => (
              <p key={`prereqs-${ind}`}>
                - {startCase(prereq)} Subscription (Upgrade Subscription{' '}
                <Link
                  to={handlePath(
                    {
                      pathname: '/plans',
                      search: location.search,
                      state: { background: location },
                    },
                    companyId,
                  )}
                >
                  here
                </Link>
                )
              </p>
            ))}
          </div>
        )}
        {!isEmpty(prereqMeta.tasks) && (
          <div className="prereq__card">
            <h4>To unlock, please complete the linked prerequisite(s) above.</h4>
            <h4>
              Already done the legal work for a prerequisite? Most workflows allow you to
              clarify that and upload the necessary documents.
            </h4>
          </div>
        )}
        <div className="prereq-modal--buttons">
          <Button buttonType="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </div>
      </div>
    </MyModal>
  );
};

ProjectPrereq.propTypes = {
  formData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string, //TODO: clean up Review PropTypes
            value: PropTypes.string,
          }),
        ),
      ]),
    }),
  ),
  title: PropTypes.string,
};

export default ProjectPrereq;
