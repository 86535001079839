import React, { useMemo } from 'react';

import classNames from 'classnames';

import { Button } from '../common';
import './DataTable.scss';
import handlePath from '../../utils/handlePath';
import { FilePreviewer } from '../FilePreviewer/FilePreviewer';
import PropTypes from 'prop-types';

const SUBHEADERS_WITH_TYPE = ['authorized_shares', 'equity_shares', 'quarterly_revenue'];
const SUBHEADERS = ['email', 'investment_type', 'ip_type', 'state_type', 'equity_class'];

export const DataRow = ({
  accessCode,
  companyId,
  isAdmin,
  isUser,
  readOnly,
  requiredDocumentsTotal,
  reports_feature_type_id,
  resource,
  resourceIndex,
  roomId,
  setIsModalOpen,
  setSelectedResource,
}) => {
  const { documentsCompleted, documentItems, resourceItems } = resource;

  const filteredResourceItems = useMemo(() => {
    let filteredArr = [];
    let isSubheaderShowing = false;
    resourceItems.forEach(e => {
      const isSubheaderWithType = SUBHEADERS_WITH_TYPE.includes(e.feature_type.name);
      const isSubheader = SUBHEADERS.includes(e.feature_type.name);
      if (e.isDocument) {
        return;
      } else if (!isUser && !isSubheaderShowing && isSubheaderWithType) {
        filteredArr[0] = {
          ...filteredArr[0],
          sublabel: e.feature.value ? `${e.label} ${e.feature_name}` : '',
        };
        isSubheaderShowing = true;
      } else if (!isUser && !isSubheaderShowing && isSubheader) {
        filteredArr[0] = {
          ...filteredArr[0],
          sublabel: e.feature.value ? e.label : '',
        };
        isSubheaderShowing = true;
      } else if (e.table_view) {
        filteredArr.push(e);
      }
    });
    return filteredArr;
  }, [resourceItems, isUser]);

  const onResourceClick = (e, resource) => {
    if (resource.isDocument) {
      e.preventDefault();
      setSelectedResource({ ...resource, isDocsShowingInitially: true });
      setIsModalOpen(true);
    }
  };
  const onDocumentsClick = e => {
    e.preventDefault();
    setSelectedResource({ ...resource, isDocsShowingInitially: true });
    setIsModalOpen(true);
  };
  const onDocumentClick = (e, initialValue) => {
    if (initialValue) {
      e.preventDefault();
    }
  };

  const isDocumentsShowing = documentsCompleted > 0 || requiredDocumentsTotal > 0;

  return (
    <div
      className={classNames('dataTable__row', {
        firstRow: resourceIndex < 1,
        isDocumentsShowing,
      })}
      onClick={e => {
        if (!e.defaultPrevented) {
          setSelectedResource(resource);
          setIsModalOpen(true);
        }
      }}
    >
      {filteredResourceItems.map((resourceFeature, index) => (
        <div
          key={`resource-list-item-${index}-${resourceFeature.feature_name}`}
          className={classNames('dataTable__item', {
            first: index === 0,
            'dataTable__item--sublabel': resourceFeature.sublabel,
          })}
          onClick={e => onResourceClick(e, resourceFeature)}
        >
          {index === 0 && (
            <>
              <h3>{resourceFeature.label}</h3>
              {resourceFeature.sublabel && <p>{resourceFeature.sublabel}</p>}
              {isUser && <p>{(resourceItems || [])[0]?.feature?.account?.name}</p>}
            </>
          )}
          {index !== 0 && (
            <>
              <h4>{resourceFeature.feature_name}</h4>
              <p>
                {resourceFeature.label}
                {!resourceFeature.feature.value &&
                  !!resourceFeature.action &&
                  !accessCode && (
                    <>
                      <Button
                        buttonType="link"
                        href={handlePath(resourceFeature.action.link_url, companyId)}
                        size="sm"
                      >
                        {resourceFeature.action.link_text}
                      </Button>
                    </>
                  )}
              </p>
            </>
          )}
        </div>
      ))}
      {(documentsCompleted > 0 || requiredDocumentsTotal > 0) && (
        <div
          className={classNames('dataTable__item dataTable__documents', {
            firstRow: resourceIndex < 1,
          })}
        >
          <h4>
            Documents
            {(!!documentsCompleted || requiredDocumentsTotal > 0) && (
              <>
                {' ('}
                <Button buttonType="link" size="xs" onClick={onDocumentsClick}>
                  view all
                </Button>
                )
              </>
            )}
          </h4>
          {documentItems.map(
            (doc, ind) =>
              !!doc.table_view &&
              (!!doc.initialValue || (!doc.initialValue && doc.new_require)) && (
                <div
                  className="dataTable__documentLabel"
                  onClick={e => onDocumentClick(e, doc.initialValue)}
                  key={`document-item-${ind}`}
                >
                  {/* {!doc.initialValue && doc.new_require && (
                    <FontAwesomeIcon
                      className="dataTable__documentRequired"
                      icon={['fal', 'times']}
                    />
                  )} */}
                  {!doc.initialValue && (
                    <p>
                      {doc.new_require && <span>*</span>}
                      {doc.feature_name}
                    </p>
                  )}
                  {!!doc.initialValue && (
                    <FilePreviewer
                      accessCode={accessCode}
                      exchangeToken={doc.feature?.exchange_token}
                      isDataRoom={!!accessCode && !isAdmin}
                      isUserTable={isUser}
                      readOnly={readOnly}
                      reportsFeatureTypeId={reports_feature_type_id}
                      resourceId={doc.resourceId}
                      isLinkOnHover
                      boxFileId={doc.initialValue}
                      customLabel={doc.feature_name}
                      roomId={roomId}
                    />
                  )}
                </div>
              ),
          )}
        </div>
      )}
    </div>
  );
};

DataRow.propTypes = {
  accessCode: PropTypes.string,
  companyId: PropTypes.string,
  isAdmin: PropTypes.bool,
  requiredDocumentsTotal: PropTypes.number,
  reports_feature_type_id: PropTypes.number,
  resource: PropTypes.object,
  resourceIndex: PropTypes.number,
  setIsModalOpen: PropTypes.func,
  setSelectedResource: PropTypes.func,
};
