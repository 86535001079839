import React, { useMemo } from 'react';
// import { useLocation } from 'react-router-dom';

import './ReportingRoom.scss';
import ReportingTable from '../../components/ReportingTable';
// import { hashContains, noHash } from '../../utils/generateMultiHash';

export const RoomReports = ({
  accessCode,
  isEditing,
  account_id,
  is_admin_user,
  readOnly,
  roomId,
  report_feature_types,
  reportsRef,
}) => {
  // const { hash } = useLocation();

  const filteredAndSortedCompanyDataTables = useMemo(() => {
    let updatedCompanyDataTables = report_feature_types.filter(table => {
      if (Object.values(table?.resourceListData || {}).length === 0 && !is_admin_user) {
        return false;
      }
      return true;
      // if (noHash(hash) || hashContains(hash, table.question_label)) {
      //   return true;
      // }
      // return false;
    });
    return updatedCompanyDataTables;
  }, [is_admin_user, report_feature_types]);

  return (
    <div ref={reportsRef}>
      {/* <SavviNavLinkBar navLinkItems={homeNavLinkItems} parentPathname={pathname} isRoom /> */}
      {/* <div className="room__section room__section--reports"> */}
      {filteredAndSortedCompanyDataTables.map((report, reportIndex) => (
        <ReportingTable
          key={`reports-${report.id}`}
          {...report}
          accountId={account_id}
          accessCode={accessCode}
          isAdmin={is_admin_user}
          isEditing={isEditing}
          isInitCollapsed={false}
          question_label={report.question_label}
          readOnly={readOnly}
          roomId={roomId}
          reportIndex={reportIndex}
          reports={report_feature_types}
        />
      ))}
      {/* </div> */}
    </div>
  );
};
