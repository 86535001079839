import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import './Room.scss';
import DataTable from '../../components/DataTable';
import { hashContains, noHash } from '../../utils/generateMultiHash';
import { SavviNavLinkBar } from '../../components/SavviNavLink';

export const RoomReports = ({
  accessCode,
  isEditing,
  account_id,
  is_admin_user,
  readOnly,
  roomId,
  report_feature_types,
}) => {
  const { hash } = useLocation();
  // const { push } = useHistory();
  const { pathname } = useLocation();

  const homeNavLinkItems = useMemo(
    () =>
      report_feature_types
        .filter(table => Object.values(table.resourceListData).length > 0)
        .map(table => ({
          id: table.name,
          isItemLengthShowing: true,
          itemLength: Object.entries(table.resourceListData).length,
          label: table.question_label,
          path: {
            pathname,
            hash: '#' + table.question_label,
          },
        })),
    [report_feature_types, pathname],
  );

  const filteredAndSortedCompanyDataTables = useMemo(() => {
    let updatedCompanyDataTables = report_feature_types.filter(table => {
      if (noHash(hash) || hashContains(hash, table.question_label)) {
        return true;
      }
      return false;
    });
    return updatedCompanyDataTables;
  }, [report_feature_types, hash]);

  return (
    <>
      <SavviNavLinkBar navLinkItems={homeNavLinkItems} parentPathname={pathname} isRoom />
      <div className="room__section room__section--reports">
        {filteredAndSortedCompanyDataTables.map((report, reportIndex) => (
          <DataTable
            key={`reports-${report.id}`}
            {...report}
            accountId={account_id}
            accessCode={accessCode}
            isAdmin={is_admin_user}
            isEditing={isEditing}
            isInitCollapsed={filteredAndSortedCompanyDataTables.length > 2}
            question_label={report.question_label}
            roomId={roomId}
            readOnly={readOnly}
            reportIndex={reportIndex}
            reports={report_feature_types}
          />
        ))}
      </div>
    </>
  );
};
