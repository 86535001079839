import '../components/FileUploader/FileUploader';

import React from 'react';

import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';

import {
  AddressGroup,
  CheckboxGroup,
  Comment,
  FormCustomDoc,
  FormFileUploader,
  Password,
  PhoneNumber,
  Select,
  ShareLinks,
  StyledCheckbox,
  TemplateLink,
  TextField,
  Textarea,
  ResourceList,
} from '../components/formik';
import { options } from '../data/StateData';
import { parseShareLink } from '../components/formik/ShareLinks/ShareLinks';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export const validateAddress = (val, isUniversal) => {
  let error;
  if (
    isEmpty(val) ||
    (isEmpty(val.line_one) && isEmpty(val.city) && isEmpty(val.state) && isEmpty(val.zip))
  ) {
    error = {
      ...error,
      address: 'Full Address required',
    };
  }
  if (isEmpty(val.line_one)) {
    error = {
      ...error,
      line_one: 'Line One required',
    };
  }
  if (isEmpty(val.city)) {
    error = {
      ...error,
      city: 'City required',
    };
  }
  if (isEmpty(val.state)) {
    error = {
      ...error,
      state: 'State required',
    };
  }
  if (isEmpty(val.zip)) {
    error = {
      ...error,
      zip: 'Zip required',
    };
  }
  if (isEmpty(val.country) && isUniversal) {
    error = {
      ...error,
      country: 'Country required',
    };
  }
  return error;
};

export const validateBoolean = val => {
  let error;
  if (!val) {
    error = 'Selection required';
  }
  return error;
};

export const validateCheckboxGroup = (val = '') => {
  let error;
  val = val.trim();
  if (isEmpty(val)) {
    error = 'Selection required';
  }
  return error;
};

export const validateCompany = (val, suffixOptions, entityType, isEntityFormed) => {
  let error = '';

  let filteredOptions = suffixOptions;
  if (entityType) {
    filteredOptions = suffixOptions.filter(e => e.type === entityType);
  }
  let optionIndex = -1;
  let suffixIndex = -1;
  for (let i = 0; i < filteredOptions.length; i++) {
    const tempSuffixIndex = (val || '').lastIndexOf(filteredOptions[i].value);
    if (
      tempSuffixIndex !== -1 &&
      (val || '').length <= tempSuffixIndex + filteredOptions[i].value.length
    ) {
      optionIndex = i;
      suffixIndex = tempSuffixIndex;
      break;
    }
  }
  if (!val || suffixIndex === 0) {
    error = 'Company must be named.';
  } else if (
    !isEntityFormed &&
    entityType &&
    !entityType.includes('Series') &&
    optionIndex === -1
  ) {
    error = 'Please select a Company suffix of your Entity Type.';
  }
  return error;
};
export const validateCurrency = (val = '') => {
  let error;
  val = val.trim();
  if (isEmpty(val)) {
    error = 'Currency required';
    return error;
  }
  if (val.length < 2) {
    error = 'Currency required';
  }
  return error;
};

export const validateDate = (val = '', isOptional, isTouched) => {
  let error;
  val = val.trim();
  let valArr = [];
  if (val.indexOf('-') !== -1) {
    valArr = val.split('-');
  } else if (val.indexOf('/') !== -1) {
    valArr = val.split('/');
  }

  if (isEmpty(val)) {
    if (!isOptional) {
      error = 'Date required';
    }
    return error;
  }
  // if (!isTouched) {
  //   return error;
  // }
  if (valArr[0] && valArr[0].length < 2) {
    error = 'Full date required';
  }
  if (!valArr[1] || (valArr[1] && valArr[1].length < 2)) {
    error = 'Month and year required';
  } else if (!valArr[2] || (valArr[2] && valArr[2].length < 4)) {
    error = 'Full 4 digit year required.';
  }
  if (val === 'Invalid date') {
    error = 'Valid date required';
  }
  return error;
};

export const validateEmail = val => {
  let error;
  if (isEmpty(val)) {
    error = 'Email required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(val)) {
    error = 'Invalid email address';
  }
  return error;
};

export const validateMonth = (val = '') => {
  let error;
  val = val.trim();
  const valArr = val.split('-');
  if (isEmpty(val)) {
    error = 'Month and Year required';
    return error;
  }
  if (valArr[0].length !== 2) {
    error = 'Full date required';
  } else if (!valArr[1] || (valArr[1] && valArr[1].length < 4)) {
    error = 'Full 4 digit year required';
  }
  return error;
};

export const validatePassword = (val = '', pwLength = 12, customErr) => {
  let error;
  val = val.trim();
  if (isEmpty(val)) {
    error = 'Password required';
  } else if (val.length < pwLength) {
    error =
      customErr ||
      'Password must be at least 12 characters long to meet industry-standard security requirements.';
  }
  return error;
};

export const validatePhone = val => {
  let error;
  val = val.trim();
  if (isEmpty(val)) {
    error = 'Field required';
  } else if (val.includes('+1') && val.length < 17) {
    error = 'Full phone number required.';
  }
  return error;
};

export const validateSalary = val => {
  let error;
  val = val.trim();
  // eslint-disable-next-line
  let parsedVal = val.replace(/[^0-9|\.]/g, '');
  if (isEmpty(parsedVal)) {
    error = 'Salary required';
    return error;
  }
  if (parsedVal.length < 2) {
    error = 'Salary required';
  }
  return error;
};

export const validateSelect = (val = '') => {
  let error;
  if (Array.isArray(val)) {
    val = val.map(e => get(e, 'label', e).trim());
    if (isEmpty(val)) {
      error = 'Field required';
    }
  } else {
    val = (val?.label || val || '').trim();
    if (isEmpty(val)) {
      error = 'Field required';
    }
  }
  return error;
};

export const validateShareLinks = val => {
  let error;
  let updatedValue = val;
  if (!Array.isArray(val)) {
    updatedValue = parseShareLink(val);
  }
  if (Array.isArray(updatedValue)) {
    updatedValue.forEach(e => {
      if (!e.label || !e.url) {
        error = 'Incomplete Link Input';
      }
    });
  }
  if (isEmpty(updatedValue)) {
    error = 'Link required';
  }
  return error;
};

export const validateSocialSecurity = val => {
  let error;
  val = val.trim();
  if (isEmpty(val)) {
    error = 'Field required';
  } else if (val.length < 9) {
    error = 'Full social security required.';
  }
  return error;
};

export const validateString = val => {
  let error;
  val = val ? (val?.value || val).trim() : '';
  if (isEmpty(val)) {
    error = 'Field required';
  }
  return error;
};

export const validateZip = val => {
  let error;
  val = val ? val.trim() : '';
  if (isEmpty(val)) {
    error = 'Field required';
  }
  return error;
};

const compareNumbers = (a, b) => {
  const aNum = a.value.replace(/[^\d.-]/g, '');
  const bNum = b.value.replace(/[^\d.-]/g, '');
  return bNum - aNum;
};

const compareStrings = (a, b) => a.value.localeCompare(b.value);

const compareDates = (a, b) => {
  return dayjs(a.value, [
    'MMM Do, YYYY',
    'MMM D, YYYY',
    'MMM. D, YYYY',
    'D MMM  YYYY',
    'MM/DD/YYYY',
    'x',
  ]).diff(
    dayjs(b.value, [
      'MMM Do, YYYY',
      'MMM D, YYYY',
      'MMM. D, YYYY',
      'D MMM  YYYY',
      'MM/DD/YYYY',
      'x',
    ]),
  );
};

export default {
  attorney: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <Select {...props} />,
    validate: validateSelect,
    compareFunc: compareStrings,
  },
  attorney_lane: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <Select {...props} />,
    validate: validateSelect,
    compareFunc: compareStrings,
  },
  address: {
    value: {
      line_one: '',
      line_two: '',
      city: '',
      state: '',
      zip: '',
    },
    gridColWidth: 'minMax(140px, 2fr)',
    Formik: ({ ...props }) => <AddressGroup options={options} {...props} />,
    validate: validateAddress,
    compareFunc: compareStrings,
  },
  address_universal: {
    value: {
      line_one: '',
      line_two: '',
      city: '',
      state: '',
      zip: '',
      country: '',
    },
    gridColWidth: 'minMax(140px, 2fr)',
    Formik: ({ ...props }) => <AddressGroup isUniversal options={options} {...props} />,
    validate: val => validateAddress(val, true),
    compareFunc: compareStrings,
  },
  boolean: {
    value: false,
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <StyledCheckbox {...props} />,
    validate: validateBoolean,
    compareFunc: compareNumbers,
  },
  checkbox_group: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <CheckboxGroup type="checkbox" {...props} />,
    validate: validateCheckboxGroup,
    compareFunc: compareStrings,
  },
  comment: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: props => <Comment {...props} />,
    compareFunc: compareStrings,
  },
  currency: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => (
      <TextField myType="currency" defaultPlaceholder="E.g., $15.99" {...props} />
    ),
    validate: validateCurrency,
    compareFunc: compareNumbers,
  },
  detailed_options: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <Select {...props} multiEntry={true} />,
    validate: validateSelect,
    compareFunc: compareStrings,
  },
  document: {
    value: '',
    gridColWidth: 'minMax(140px, 1.33fr)',
    Formik: ({ ...props }) => <FormFileUploader {...props} />,
    validate: validateString,
    compareFunc: compareStrings,
  },
  date: {
    value: '',
    gridColWidth: 'minMax(112px, 1fr)',
    Formik: ({ ...props }) => (
      <TextField myType="date" defaultPlaceholder="E.g., 03/21/1991" {...props} />
    ),
    validate: validateDate,
    compareFunc: compareDates,
  },
  email: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <TextField type="email" {...props} />,
    validate: validateEmail,
    compareFunc: compareStrings,
  },
  template_document: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <TemplateLink {...props} />,
    compareFunc: compareStrings,
  },
  first_name: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <TextField {...props} />,
    validate: validateString,
    compareFunc: compareStrings,
  },
  float: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <TextField {...props} myType="float" />,
    validate: validateString,
    compareFunc: compareNumbers,
  },
  float_currency: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => (
      <TextField myType="float_currency" defaultPlaceholder="E.g., $0.00001" {...props} />
    ),
    validate: validateCurrency,
    compareFunc: compareNumbers,
  },
  individual: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <TextField {...props} />,
    validate: validateSelect,
    compareFunc: compareStrings,
  },
  integer: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <TextField myType="integer" {...props} />,
    validate: validateString,
    compareFunc: compareNumbers,
  },
  last_name: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <TextField {...props} />,
    validate: validateString,
    compareFunc: compareStrings,
  },
  month: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => (
      <TextField myType="month" defaultPlaceholder="E.g., 07/2019" {...props} />
    ),
    validate: validateMonth,
    compareFunc: compareDates,
  },
  name: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => (
      <TextField defaultPlaceholder="E.g., Henry Weinhurst" {...props} />
    ),
    validate: validateSelect,
    compareFunc: compareStrings,
  },
  number: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <TextField {...props} myType="float" />,
    validate: validateString,
  },
  options: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => (
      <Select defaultPlaceholder="Type to search or select option..." {...props} />
    ),
    validate: validateSelect,
    compareFunc: compareStrings,
  },
  person_name: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => (
      <TextField defaultPlaceholder="E.g., Henry Weinhurst" {...props} />
    ),
    validate: validateSelect,
    compareFunc: compareStrings,
  },
  percent: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => (
      <TextField myType="percent" defaultPlaceholder="E.g., 75%" {...props} />
    ),
    validate: validateString,
    compareFunc: compareNumbers,
  },
  phone: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => (
      <PhoneNumber defaultPlaceholder="E.g., +1 (801) 792-2031" {...props} />
    ),
    validate: validatePhone,
    compareFunc: compareStrings,
  },
  related_resource: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => (
      <Select defaultPlaceholder="Type to search or select option..." {...props} />
    ),
    validate: validateSelect,
    compareFunc: compareStrings,
  },
  radio: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <CheckboxGroup type="radio" {...props} />,
    validate: validateCheckboxGroup,
    compareFunc: compareStrings,
  },
  resource_list: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <ResourceList {...props} />,
    validate: validateString,
    compareFunc: compareStrings,
  },
  salary: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => (
      <TextField myType="salary" defaultPlaceholder="E.g., $65,000" {...props} />
    ),
    validate: validateSalary,
    compareFunc: compareNumbers,
  },
  share_links: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <ShareLinks {...props} />,
    validate: validateShareLinks,
    compareFunc: compareStrings,
  },
  social_security: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => (
      <Password autoComplete="nope" type="text" myType="social_security" {...props} />
    ),
    validate: validateSocialSecurity,
    compareFunc: compareStrings,
  },
  state: {
    value: '',
    Formik: ({ ...props }) => (
      <Select
        {...props}
        defaultPlaceholder="Type to search or select option..."
        options={options}
      />
    ),
    gridColWidth: 'minMax(120px, 1fr)',
    validate: validateSelect,
    compareFunc: compareStrings,
  },
  string: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <TextField {...props} />,
    validate: validateString,
    compareFunc: compareStrings,
  },
  custom_document: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <FormCustomDoc {...props} />,
    validate: validateString,
    compareFunc: compareStrings,
  },
  text: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <Textarea {...props} />,
    validate: validateString,
    compareFunc: compareStrings,
  },
  zip: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => (
      <TextField myType="zip" type="tel" defaultPlaceholder="E.g., 84057" {...props} />
    ),
    validate: validateZip,
    compareFunc: compareStrings,
  },
};
