import React, { useState } from 'react';

import './RedirectBanner.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../Button';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(utc);
dayjs.extend(relativeTime);

const RedirectBanner = () => {
  const params = new URLSearchParams(window.location.search);
  const redirectFrom = params.get('redirectFrom');
  let isRedirected = !!localStorage.getItem('redirectFrom') || !!redirectFrom;
  let isHidden = !!localStorage.getItem('hideRedirectFrom');

  const [isShowing, setIsShowing] = useState(isRedirected && !isHidden);
  const handleHide = () => {
    setIsShowing(false);
    localStorage.setItem('hideRedirectFrom', 1);
  };

  return (
    <div className={`redirectBanner${!isShowing ? ' redirectBanner--hide' : ''}`}>
      <FontAwesomeIcon className="redirectBanner__icon" icon="exclamation-circle" />
      <h4>
        You have been redirected from app.savvi.legal because our company's primary brand
        is now PaperOS! All data and features remain unchanged.
      </h4>
      <Button
        buttonType="icon"
        size="xs"
        className="redirectBanner__hide"
        onClick={handleHide}
      >
        <FontAwesomeIcon icon="times" />
      </Button>
    </div>
  );
};

export default RedirectBanner;
